import { FC } from "react";
import s from "./Loader.module.scss";

import Preloader from "../../../shared/Preloader/Preloader";

export const Loader: FC = ({ ...props }) => {
  return (
    <div className={s.wrapper}>
      <Preloader />
      <div className={s.title}>One moment...</div>
      <div className={s.subtitle}>Placing cool stuff takes time</div>
    </div>
  );
};
