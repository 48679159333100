import { filterT } from "./constants";

export type categoryDataT = {
  value: string;
  label: string;
  image?: any;
  imgCover?: any;
  tags: filterT[];
  [key: string]: any;
};

export const dataRoom: categoryDataT[] = [];
export const dataWall: categoryDataT[] = [];
export const dataFloor: categoryDataT[] = [];
export const dataBackground: categoryDataT[] = [];
export const dataSofa: categoryDataT[] = [
  {
    value: "111",
    label: "Soft couch",
    imgCover: "111",
    tags: ["Black color"],
  },
  {
    value: "122",
    label: "Cozy sofa",
    image: "122",
    tags: ["Black color"],
  },
  {
    value: "130",
    label: "Plush seat",
    image: "130",
    tags: ["Black color"],
  },
];

export const dataBed: categoryDataT[] = [];

export const dataStorage: categoryDataT[] = [];

export const dataTable: categoryDataT[] = [
  {
    value: "106",
    label: "Table",
    image: "106",
    tags: ["White color"],
  },
  {
    value: "112",
    label: "Metal Desk",
    image: "112",
    tags: ["Black color"],
  },
  {
    value: "123",
    label: "Wood Table",
    image: "123",
    tags: ["White color"],
  },
];

export const dataChair: categoryDataT[] = [
  {
    value: "125",
    label: "Modern Seat",
    image: "125",
    tags: ["Black color"],
  },
  {
    value: "133",
    label: "Comfy Chair",
    image: "133",
    tags: [],
  },
];

export const dataLight: categoryDataT[] = [
  {
    value: "109",
    label: "Desk Lamp",
    image: "109",
    tags: ["Black color"],
  },
  {
    value: "124",
    label: "Table Light",
    image: "124",
    tags: ["White color"],
  },
  {
    value: "129",
    label: "Ceiling Bulb",
    image: "129",
    tags: ["Black color"],
  },
  {
    value: "135",
    label: "Wall Sconce",
    image: "135",
    tags: ["White color"],
  },
];

export const dataPlant: categoryDataT[] = [
  {
    value: "107",
    label: "Green Fern",
    image: "107",
    tags: ["Black color"],
  },
  {
    value: "108",
    label: "Potted Palm",
    image: "108",
    tags: ["Black color"],
  },
];

export const dataDecor: categoryDataT[] = [
  {
    value: "104",
    label: "Eyewear",
    image: "104",
    tags: ["Black color"],
  },
  {
    value: "113",
    label: "hollo",
    image: "113",
    tags: ["Black color"],
  },
  {
    value: "114",
    label: "Forest Scene",
    image: "114",
    tags: ["Black color"],
  },
  {
    value: "115",
    label: "Mystery",
    image: "115",
    tags: ["White color"],
  },
  {
    value: "116",
    label: "Abstract",
    image: "116",
    tags: ["Black color"],
  },
  {
    value: "117",
    label: "Ocean View",
    image: "117",
    tags: ["Black color"],
  },
  {
    value: "118",
    label: "Floral Print",
    image: "118",
    tags: ["Black color"],
  },
  {
    value: "126",
    label: "Soft Rug",
    image: "126",
    tags: ["White color"],
  },
  {
    value: "127",
    label: "Color Print",
    image: "127",
    tags: ["White color"],
  },
  {
    value: "128",
    label: "Patterned",
    image: "128",
    tags: ["White color"],
  },
  {
    value: "131",
    label: "Sunset Art",
    image: "131",
    tags: ["White color"],
  },
  {
    value: "132",
    label: "Dino Figure",
    image: "132",
    tags: ["White color"],
  },

  {
    value: "134",
    label: "Cozy Carpet",
    image: "134",
    tags: ["White color"],
  },
];
