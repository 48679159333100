import React from 'react';

export const CopyIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.625 16.5H6.125C5.09102 16.5 4.25 15.659 4.25 14.625V6.125C4.25 5.09102 5.09102 4.25 6.125 4.25H14.625C15.659 4.25 16.5 5.09102 16.5 6.125V14.625C16.5 15.659 15.659 16.5 14.625 16.5ZM6.125 5.5C5.78037 5.5 5.5 5.78037 5.5 6.125V14.625C5.5 14.9696 5.78037 15.25 6.125 15.25H14.625C14.9696 15.25 15.25 14.9696 15.25 14.625V6.125C15.25 5.78037 14.9696 5.5 14.625 5.5H6.125ZM3 11.5H2.375C2.03037 11.5 1.75 11.2196 1.75 10.875V2.375C1.75 2.03037 2.03037 1.75 2.375 1.75H10.875C11.2196 1.75 11.5 2.03037 11.5 2.375V2.96875H12.75V2.375C12.75 1.34102 11.909 0.5 10.875 0.5H2.375C1.34102 0.5 0.5 1.34102 0.5 2.375V10.875C0.5 11.909 1.34102 12.75 2.375 12.75H3V11.5Z"
        fill="#CF202E"
      />
    </svg>
  );
};
