import { FC } from "react";

export const MouseWheelClick: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2787 9.38721C11.2787 8.98877 11.6016 8.66577 12 8.66577C12.3983 8.66577 12.7213 8.98877 12.7213 9.38721V11.2786C12.7213 11.677 12.3983 12 12 12C11.6016 12 11.2787 11.677 11.2787 11.2786V9.38721Z"
        fill="#37CC8F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C2.68628 0 0 2.68628 0 6V18C0 21.3137 2.68628 24 6 24H18C21.3137 24 24 21.3137 24 18V6C24 2.68628 21.3137 0 18 0H6ZM7.56723 10C7.56723 7.79077 9.35806 6 11.5672 6H12.4328C14.6419 6 16.4328 7.79077 16.4328 10V14C16.4328 16.2092 14.6419 18 12.4328 18H11.5672C9.35806 18 7.56723 16.2092 7.56723 14V10Z"
        fill="#17172E"
      />
    </svg>
  );
};
