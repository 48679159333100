import { Filter } from "../../../../shared/Filter/Filter";
import { Search } from "../../../../shared/Search/Search";
import { SortBy } from "../../../../shared/SortBy/SortBy";
import { categoryDataT } from "../../../../utils/categoriesData";
import { categoryesListT, filterT } from "../../../../utils/constants";
import s from "./ItemSettings.module.scss";
import { FC, useEffect, useState } from "react";

type ItemSettingsT = {
  data: categoryesListT;
  items: categoryDataT[];
  setItems: any;
};
function filterAndSort(
  array: categoryDataT[],
  sortByA: boolean,
  activeFilterNames: filterT[],
  searchValue: string
) {
  // Копируем исходный массив, чтобы не изменять его
  let result = [...array];

  // Фильтрация по активным тегам
  if (activeFilterNames && activeFilterNames.length > 0) {
    result = result.filter((item) =>
      activeFilterNames.some((tag) => item.tags.includes(tag))
    );
  }

  // Поиск по строке
  if (searchValue) {
    const searchString = searchValue.toLowerCase();
    result = result.filter((item) =>
      item.label.toLowerCase().includes(searchString)
    );
  }

  // Сортировка
  if (sortByA) {
    result.sort((a, b) => a.label.localeCompare(b.label));
  } else {
    result.sort((a, b) => b.label.localeCompare(a.label));
  }

  return result;
}

const filterData: filterT[] = ["White color", "Black color"];
export const ItemSettings: FC<ItemSettingsT> = ({ ...props }) => {
  const { data, items, setItems } = props;
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue]: any = useState([]);
  // const [filterValue, setFilterValue] = useState<filterT[]>([]);
  const [sortValue, setSortValue] = useState(0);

  useEffect(() => {
    const sortByA = sortValue === 0 ? true : false;
    const activefilterNames: filterT[] = [];
    filterValue.map((item: number) => {
      activefilterNames.push(filterData[item]);
    });
    const filteredAndSortedData = filterAndSort(
      data.data,
      sortByA,
      activefilterNames,
      searchValue
      );

    setItems(filteredAndSortedData);
  }, [searchValue, filterValue, sortValue]);
  return (
    <div className={s.wrapper}>
      <div className={s.wrap}>
        {data.search && (
          <Search value={searchValue} setValue={setSearchValue} />
        )}
        {data.filter && (
          <Filter
            filterData={filterData}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
          />
        )}
      </div>
      {data.sort && <SortBy value={sortValue} setValue={setSortValue} />}
    </div>
  );
};
