import { FC } from "react";

export const MoveIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="move" clipPath="url(#clip0_960_4927)">
        <path
          id="Vector"
          d="M22.7897 18.6273L21.5662 17.9882V7.81969C21.5662 7.56877 21.4311 7.33495 21.2139 7.20961L12.4248 2.13525V0.469688C12.4248 0.210328 12.2145 0 11.9551 0C11.6958 0 11.4854 0.210281 11.4854 0.469688V2.1022L2.63925 7.20956C2.42193 7.33495 2.28689 7.56877 2.28689 7.81969V18.0285L1.14257 18.6275C0.912793 18.7477 0.823965 19.0315 0.944293 19.2614C1.0282 19.4217 1.19161 19.5134 1.36082 19.5134C1.43423 19.5134 1.50872 19.4961 1.57823 19.4597L2.78189 18.8296L11.5744 23.906C11.683 23.9687 11.8048 24 11.9265 24C12.0483 24 12.1702 23.9686 12.2788 23.9059L15.0112 22.3283C15.2359 22.1986 15.3129 21.9114 15.1831 21.6868C15.0535 21.4622 14.7663 21.3851 14.5416 21.5149L11.9265 23.0247L3.76725 18.3139L7.25559 16.488L11.5672 18.9772C11.6758 19.04 11.7975 19.0713 11.9193 19.0713C12.0411 19.0713 12.1629 19.0399 12.2716 18.9772L16.6221 16.4655L20.1215 18.2933L18.1885 19.4093C17.9638 19.539 17.8868 19.8262 18.0166 20.0509C18.1463 20.2755 18.4334 20.3526 18.6582 20.2228L21.1079 18.8085L22.3549 19.4598C22.4243 19.4961 22.4987 19.5133 22.5719 19.5133C22.7413 19.5133 22.9049 19.4214 22.9887 19.261C23.1087 19.0311 23.0197 18.7474 22.7897 18.6273ZM20.6268 7.95534V17.4975L17.0984 15.6546V10.6168C17.0984 10.3659 16.9634 10.1322 16.7461 10.0066L12.4249 7.51172V3.21994L20.6268 7.95534ZM11.9193 8.30452L15.6514 10.4593L11.9052 12.4038L8.18479 10.4606L11.9193 8.30452ZM7.67948 11.2564L11.4349 13.2179V17.8162L7.67948 15.648V11.2564ZM12.3742 13.2188L16.1591 11.2542V15.6481L12.3742 17.8333V13.2188ZM3.22622 7.95534L11.4855 3.1868V7.47028L7.09228 10.0067C6.87506 10.1322 6.74011 10.3659 6.74011 10.6168V15.6975L3.22622 17.5368V7.95534Z"
          fill="#17172E"
        />
        <path
          id="Vector_2"
          d="M16.5964 21.3383C16.8558 21.3383 17.0661 21.128 17.0661 20.8686C17.0661 20.6092 16.8558 20.3989 16.5964 20.3989C16.337 20.3989 16.1267 20.6092 16.1267 20.8686C16.1267 21.128 16.337 21.3383 16.5964 21.3383Z"
          fill="#17172E"
        />
      </g>
      <defs>
        <clipPath id="clip0_960_4927">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
