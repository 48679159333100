import { ArrowDropdown } from "../../assets/svg/ArrowDropdown";
import { SortA } from "../../assets/svg/SortA";
import { SortZ } from "../../assets/svg/SortZ";
import s from "./SortBy.module.scss";
import { FC, useState, useEffect, useRef } from "react";

type SortByT = {
  setValue: any;
  value: number;
};
const sortData: any[] = [
  {
    icon: SortA,
    label: "Sort from A to Z",
  },
  {
    icon: SortZ,
    label: "Sort from Z to A",
  },
];
export const SortBy: FC<SortByT> = ({ ...props }) => {
  const { value, setValue } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  function toggleAccount(val: any) {
    setValue(val);
  }
  const wrapperRef: any = useRef(null);
  function closeFunc() {
    setIsOpen(false);
  }
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  return (
    <div className={s.wrapper}>
      <div
        className={`${s.title} ${isOpen && s.active}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>Sort by</span>
        <ArrowDropdown />
      </div>
      {isOpen && (
        <div className={s.content} ref={wrapperRef}>
          {sortData.map((item: any, index: number) => {
            return (
              <div
                className={`${s.item} ${value === index && s.active}`}
                onClick={() => {
                  toggleAccount(index);
                }}
                key={`sort_${index}`}
              >
                <div className={s.icon}>{<item.icon />}</div>
                <div className={s.text}>{item.label}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
