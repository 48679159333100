import { FC } from "react";
import { apiAssets } from "../../constants";
import { changeShowModal, setSelectedModel } from "../../../redux/actions";

type loadAssetT = {
  id: any;
  assetId: string;
  dispatch: any;
};

export async function loadAsset({ ...props }: loadAssetT) {
  const { id, assetId, dispatch } = props;
  dispatch(changeShowModal({ nameModal: "loader", stateModal: true }));
  //@ts-ignore
  const containerRef = window.containerRef;
  const canvasIframe = containerRef.current.contentWindow;
  canvasIframe.SetMovementMode(1);
  dispatch(setSelectedModel({ selectedType: "move" }));
  canvasIframe.LoadAsset(id, assetId).then((e: any) => {
    dispatch(changeShowModal({ nameModal: "loader", stateModal: false }));
  });
}
