import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./pages/Index/Index";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/:idProject/" element={<App />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
