import { FC } from "react";

export const LogoWithText: FC = () => {
  return (
    <svg
      width="104"
      height="36"
      viewBox="0 0 104 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1091 26.9289C14.1091 24.2569 15.8668 22.0909 18.6091 22.0909C21.3515 22.0909 23.1091 24.2569 23.1091 26.9289C23.1091 29.6008 18.9195 36 18.6091 36C18.2988 36 14.1091 29.6008 14.1091 26.9289Z"
        fill="#2A8EFA"
      />
      <path
        d="M22.291 9.07115C22.291 11.7431 20.5334 13.9091 17.791 13.9091C15.0486 13.9091 13.291 11.7431 13.291 9.07115C13.291 6.39922 17.4807 0 17.791 0C18.1014 0 22.291 6.39922 22.291 9.07115Z"
        fill="#FF4F79"
      />
      <path
        d="M27.1288 22.0908C24.4569 22.0908 22.2909 20.3332 22.2909 17.5908C22.2909 14.8484 24.4569 13.0908 27.1288 13.0908C29.8007 13.0908 36.2 17.2805 36.2 17.5908C36.2 17.9012 29.8007 22.0908 27.1288 22.0908Z"
        fill="#37CC8F"
      />
      <path
        d="M9.2711 13.9092C11.943 13.9092 14.109 15.6668 14.109 18.4092C14.109 21.1516 11.943 22.9092 9.2711 22.9092C6.59917 22.9092 0.199951 18.7195 0.199951 18.4092C0.199951 18.0988 6.59917 13.9092 9.2711 13.9092Z"
        fill="#EAC159"
      />
      <path
        d="M8.49741 5.85462C9.37623 6.75388 9.53026 8.05425 8.65902 8.94576C7.78777 9.83726 6.51695 9.67964 5.63813 8.78039C4.75931 7.88113 3.98558 4.36542 4.08418 4.26454C4.18277 4.16365 7.61858 4.95537 8.49741 5.85462Z"
        fill="#2A8EFA"
      />
      <path
        d="M8.49741 29.3274C9.37623 28.4281 9.53026 27.1278 8.65902 26.2363C7.78777 25.3447 6.51695 25.5024 5.63813 26.4016C4.75931 27.3009 3.98558 30.8166 4.08418 30.9175C4.18277 31.0184 7.61858 30.2266 8.49741 29.3274Z"
        fill="#37CC8F"
      />
      <path
        d="M27.0844 5.85462C26.2056 6.75388 26.0515 8.05425 26.9228 8.94576C27.794 9.83726 29.0648 9.67964 29.9437 8.78039C30.8225 7.88113 31.5962 4.36542 31.4976 4.26454C31.399 4.16365 27.9632 4.95537 27.0844 5.85462Z"
        fill="#EAC159"
      />
      <path
        d="M27.0844 29.3274C26.2056 28.4281 26.0515 27.1278 26.9228 26.2363C27.794 25.3447 29.0648 25.5024 29.9437 26.4016C30.8225 27.3009 31.5962 30.8166 31.4976 30.9175C31.399 31.0184 27.9632 30.2266 27.0844 29.3274Z"
        fill="#FF4F79"
      />
      <path
        d="M51.8185 14.5703H56.0629L52.1937 26.6317H47.2692L43.3999 14.5703H47.7147L49.6376 22.6266H49.8956L51.8185 14.5703Z"
        fill="#343A40"
      />
      <path
        d="M61.7098 13.3043C61.1157 13.3043 60.5998 13.0972 60.1621 12.6829C59.7244 12.2532 59.5055 11.7468 59.5055 11.1637C59.5055 10.5806 59.7244 10.0742 60.1621 9.6445C60.5998 9.21483 61.1157 9 61.7098 9C62.3039 9 62.8198 9.21483 63.2575 9.6445C63.6952 10.0742 63.9141 10.5806 63.9141 11.1637C63.9141 11.7468 63.6952 12.2532 63.2575 12.6829C62.8198 13.0972 62.3039 13.3043 61.7098 13.3043ZM60.1621 26.6317V17.7928H58.2861V14.5703H63.9141V26.6317H60.1621Z"
        fill="#343A40"
      />
      <path
        d="M75.4938 14.5703H79.7383L75.869 26.6317H70.9445L67.0753 14.5703H71.3901L73.313 22.6266H73.5709L75.4938 14.5703Z"
        fill="#343A40"
      />
      <path
        d="M85.3852 13.3043C84.7911 13.3043 84.2752 13.0972 83.8375 12.6829C83.3997 12.2532 83.1809 11.7468 83.1809 11.1637C83.1809 10.5806 83.3997 10.0742 83.8375 9.6445C84.2752 9.21483 84.7911 9 85.3852 9C85.9792 9 86.4951 9.21483 86.9329 9.6445C87.3706 10.0742 87.5895 10.5806 87.5895 11.1637C87.5895 11.7468 87.3706 12.2532 86.9329 12.6829C86.4951 13.0972 85.9792 13.3043 85.3852 13.3043ZM83.8375 26.6317V17.7928H81.9615V14.5703H87.5895V26.6317H83.8375Z"
        fill="#343A40"
      />
      <path
        d="M100.248 9.36829H104V26.6317H100.248V25.2967H100.084C99.7398 25.8184 99.2864 26.2327 98.7236 26.5396C98.1608 26.8465 97.4886 27 96.7069 27C95.6908 27 94.784 26.7391 93.9867 26.2174C93.2051 25.6803 92.5876 24.9284 92.1342 23.9616C91.6965 22.9949 91.4776 21.8747 91.4776 20.601C91.462 19.312 91.673 18.1918 92.1107 17.2404C92.5641 16.2737 93.1894 15.5294 93.9867 15.0077C94.784 14.4706 95.6908 14.202 96.7069 14.202C97.473 14.202 98.1296 14.3478 98.6767 14.6394C99.2239 14.9309 99.6695 15.3223 100.013 15.8133H100.248V9.36829ZM97.7622 23.5473C98.5126 23.5473 99.1145 23.2788 99.5678 22.7417C100.021 22.2046 100.248 21.491 100.248 20.601C100.248 19.711 100.021 18.9974 99.5678 18.4604C99.1145 17.9233 98.5126 17.6547 97.7622 17.6547C97.0274 17.6547 96.4334 17.9233 95.98 18.4604C95.5266 18.9974 95.2999 19.711 95.2999 20.601C95.2999 21.491 95.5266 22.2046 95.98 22.7417C96.4334 23.2788 97.0274 23.5473 97.7622 23.5473Z"
        fill="#343A40"
      />
    </svg>
  );
};
