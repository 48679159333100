import store from "../../redux/store";

export function genDataSceneToSend(body: any) {
    const state = store.getState();
    const result: any = {
      stageId: 1,
    };
    const productsArr: any = [];
    let cameraParam: any = {};
    const products = state.ui.products;
  
    JSON.parse(body).map((item: any, index: number) => {
      if (item.id && item.id === "camera") {
        cameraParam = {
          position: item.pos,
          rotation: item.rot,
          fov: 90,
        };
      } else {
        const actualProduct: any = products.find((pr: any) => pr.id === item.id);
        productsArr.push({
          position: item.pos,
          rotation: item.rot,
          scale: item.scale,
          id: actualProduct.id,
          resource: actualProduct.resource,
          mountPoint: actualProduct.mountPoint,
          glbBundle: actualProduct.glbBundle,
        });
      }
    });
    result.products = productsArr;
    result.camera = cameraParam;
  
    return result;
  }