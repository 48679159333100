import { FC } from "react";

export const SearchIcon: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M14.1413 11.9433C15.1093 10.6218 15.5426 8.98347 15.3545 7.35617C15.1664 5.72888 14.3708 4.2326 13.1269 3.16669C11.883 2.10077 10.2825 1.54384 8.64565 1.6073C7.00875 1.67076 5.45617 2.34995 4.29853 3.50897C3.14089 4.66799 2.46356 6.22137 2.40205 7.85835C2.34054 9.49532 2.89938 11.0952 3.96678 12.3378C5.03417 13.5804 6.5314 14.3742 8.15892 14.5603C9.78644 14.7465 11.4242 14.3112 12.7446 13.3416C12.7746 13.3816 12.8054 13.42 12.8413 13.4566L16.6913 17.3066C16.8788 17.4943 17.1332 17.5997 17.3985 17.5998C17.6638 17.5999 17.9182 17.4946 18.1059 17.3071C18.2935 17.1195 18.399 16.8651 18.399 16.5999C18.3991 16.3346 18.2938 16.0801 18.1063 15.8925L14.2563 12.0425C14.2205 12.0063 14.1821 11.9737 14.1413 11.9433ZM14.3996 8.09998C14.3996 8.82225 14.2573 9.53745 13.9809 10.2047C13.7045 10.872 13.2994 11.4783 12.7887 11.9891C12.278 12.4998 11.6717 12.9049 11.0044 13.1813C10.3371 13.4577 9.62188 13.6 8.89961 13.6C8.17734 13.6 7.46214 13.4577 6.79485 13.1813C6.12756 12.9049 5.52124 12.4998 5.01052 11.9891C4.4998 11.4783 4.09467 10.872 3.81827 10.2047C3.54187 9.53745 3.39961 8.82225 3.39961 8.09998C3.39961 6.64129 3.97907 5.24234 5.01052 4.21089C6.04197 3.17944 7.44092 2.59998 8.89961 2.59998C10.3583 2.59998 11.7572 3.17944 12.7887 4.21089C13.8201 5.24234 14.3996 6.64129 14.3996 8.09998Z"
          fill="#7D7D8A"
        />
      </g>
    </svg>
  );
};
