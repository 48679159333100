import store from "../../redux/store";

export function getDataStaticEnvironment() {
  const state = store.getState();
  const result: any = [];
  const environments = state.ui.environments;
  const selectedVariants: any = state.ui.selectedVariants;

  environments.map((item: any) => {
    result.push({
      name: item.name,
      mountPoint: item.mountPoint,
      resource: item.resource,
      val: selectedVariants[item.name],
    });
  });

  return result;
}
