import { FC, useState, useEffect } from "react";
import s from "./Index.module.scss";
import { Player } from "../../widgets";
import { Interface } from "../../widgets/Interface/Interface";
import { ModalFrame } from "../../processes/modals/ModalFrame/ModalFrame";
import { MobileHint } from "../../shared/MobileHint/MobileHint";
import api from "../../processes/api";
import WebSocketComponent from "../../features/WebSocketComponent/WebSocketComponent";

export const App: FC = () => {
  return (
    <>
      <div className={s.wrapper}>
        <Player />
        <Interface />
        <ModalFrame />
        <WebSocketComponent />
      </div>
      <MobileHint />
    </>
  );
};
