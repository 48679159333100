import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalPleaseNote.module.scss";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import { changeShowModal } from "../../../redux/actions";

type ModalPleaseNoteT = {
  closeFunc: any;
};
export const ModalPleaseNote: FC<ModalPleaseNoteT> = ({ ...props }) => {
  const { closeFunc } = props;
  const wrapperRef: any = useRef(null);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  function onSubmitForm() {
    // popUp({ type: "success", message: "Email sent successfully." });
    if (show) {
      sessionStorage.setItem("dontShowNote", `${show}`);
    }
    closeFunc();
    dispatch(changeShowModal({ nameModal: "renderLoader", stateModal: true }));
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.titleWrapper}>
        <div className={s.title}>Please note</div>
        <div className={s.subtitle}>
          The image will be rendered from the angle that was previously
          set/selected. Continue rendering?
        </div>
      </div>
      <div
        className={`${s.checker} ${show && s.active}`}
        onClick={() => setShow(!show)}
      >
        <div className={s.checkbox}></div>
        <span>Do not show again</span>
      </div>

      <footer>
        <div className={s.nav}>
          <div className={s.cancel} onClick={closeFunc}>
            Cancel
          </div>
          <div className={`${s.accept}`} onClick={() => onSubmitForm()}>
            Confirm
          </div>
        </div>
      </footer>
    </div>
  );
};
