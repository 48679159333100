import { FC } from "react";

export const RotateIcon: FC = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="rotate_">
        <path
          id="Vector"
          d="M4.58761 13.9416L11.625 18.0046C11.7698 18.0883 11.9323 18.1301 12.0947 18.1301C12.2571 18.1301 12.4196 18.0883 12.5644 18.0046L19.6017 13.9416C19.8915 13.7743 20.0714 13.4626 20.0714 13.128V5.00198C20.0714 4.66744 19.8914 4.35572 19.6017 4.18852L12.5643 0.125437C12.2746 -0.0418125 11.9147 -0.0418125 11.625 0.125437L4.58761 4.18852C4.29792 4.35577 4.11792 4.66748 4.11792 5.00198V13.1281C4.11792 13.4626 4.29792 13.7743 4.58761 13.9416ZM12.5409 16.9335V14.8379C12.5409 14.5785 12.3306 14.3682 12.0712 14.3682C11.8118 14.3682 11.6015 14.5785 11.6015 14.8379V16.9064L5.05725 13.1281V5.50598L11.6015 8.9242V10.5835C11.6015 10.8429 11.8118 11.0532 12.0712 11.0532C12.3306 11.0532 12.5409 10.8429 12.5409 10.5835V8.925L19.1321 5.50373V13.128L12.5409 16.9335ZM12.0947 0.938906L18.6245 4.70888L12.0719 8.11008L5.56265 4.71019L12.0947 0.938906Z"
          fill="#17172E"
        />
        <path
          id="Vector_2"
          d="M19.8711 17.3833L20.7392 17.4037C20.9988 17.4105 21.2136 17.2045 21.2197 16.9452C21.2258 16.6859 21.0206 16.4708 20.7612 16.4647L18.8871 16.4207C18.5412 16.411 18.2511 16.6883 18.243 17.0353L18.199 18.9093C18.1929 19.1687 18.3982 19.3838 18.6575 19.3899C18.6612 19.39 18.665 19.39 18.6687 19.39C18.923 19.39 19.1321 19.1869 19.1381 18.9314L19.1593 18.026C19.8754 18.5386 20.2624 19.1108 20.2624 19.6737C20.2624 20.4949 19.4364 21.3324 17.996 21.9716C16.4131 22.6741 14.2982 23.0609 12.0409 23.0609C9.78349 23.0609 7.66863 22.6741 6.08571 21.9716C4.64534 21.3325 3.81926 20.4949 3.81926 19.6737C3.81926 19.2972 3.98787 18.9199 4.3204 18.5524C4.67805 18.157 5.20291 17.793 5.8804 17.4704C6.11463 17.3589 6.21405 17.0786 6.10254 16.8444C5.99102 16.6102 5.71076 16.5108 5.47657 16.6223C3.80205 17.4196 2.87988 18.5033 2.87988 19.6737C2.87988 20.9009 3.8831 22.0218 5.70466 22.8302C7.40496 23.5846 9.65515 24.0001 12.0409 24.0001C14.4266 24.0001 16.6767 23.5846 18.3769 22.8301C20.1986 22.0218 21.2018 20.9008 21.2018 19.6736C21.2018 18.8365 20.7453 18.0562 19.8711 17.3833Z"
          fill="#17172E"
        />
        <path
          id="Vector_3"
          d="M12.0879 13.1877C12.3473 13.1877 12.5575 12.9774 12.5575 12.718C12.5575 12.4586 12.3473 12.2483 12.0879 12.2483C11.8285 12.2483 11.6182 12.4586 11.6182 12.718C11.6182 12.9774 11.8285 13.1877 12.0879 13.1877Z"
          fill="#17172E"
        />
      </g>
    </svg>
  );
};
