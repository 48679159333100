import { MouseWheelClick } from "../../assets/svg/MouseWheelClick";
import { QE } from "../../assets/svg/QE";
import { RightClick } from "../../assets/svg/RightClick";
import { WASD } from "../../assets/svg/WASD";
import s from "./SceneHint.module.scss";

export const SceneHint = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.item}>
        <div className={s.image}>
          <RightClick />
        </div>
        <div className={s.text}>
          <b>Hold RMB </b>to rotate the camera position
        </div>
      </div>
      <div className={s.item}>
        <div className={s.image}>
          <MouseWheelClick />
        </div>
        <div className={s.text}>
          <b>Hold Mouse Wheel </b>to drag the camera position
        </div>
      </div>
      <div className={s.item}>
        <div className={s.image}>
          <WASD />
        </div>
        <div className={s.text}>
          <b>Use WASD </b>to move the camera position
        </div>
      </div>
      <div className={s.item}>
        <div className={s.image}>
          <QE />
        </div>
        <div className={s.text}>
          <b>Use Q & E </b>to raise / lower the camera
        </div>
      </div>
    </div>
  );
};
