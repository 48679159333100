import { FC } from "react";

export const SnapIcon: FC = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4738 12.0707L19.8974 9.49433C19.8169 9.41377 19.7076 9.36848 19.5936 9.36848C19.4796 9.36848 19.3703 9.41377 19.2898 9.49433L12.5639 16.2202C10.8588 17.9253 8.08442 17.9253 6.37938 16.2202C4.67434 14.5152 4.67434 11.7408 6.37938 10.0358L7.14018 9.27502C7.30798 9.10723 7.30802 8.83515 7.14018 8.66736C6.97239 8.49952 6.70031 8.49961 6.53252 8.66736L5.77172 9.42816C3.83391 11.366 3.73697 14.458 5.48043 16.5116L3.50743 18.4846C2.18344 17.0133 1.45898 15.1276 1.45898 13.128C1.45898 10.9841 2.29146 8.97097 3.80306 7.45942L8.11875 3.14372L10.0874 5.11238L9.26688 5.93287C9.09909 6.10066 9.09909 6.37274 9.26688 6.54057C9.43467 6.70841 9.70675 6.70837 9.87454 6.54057L13.1052 3.30988C13.1858 3.22932 13.2311 3.12 13.2311 3.00605C13.2311 2.8921 13.1858 2.78279 13.1052 2.70222L10.529 0.125855C10.4483 0.0452891 10.3391 0 10.2251 0C10.1111 0 10.0018 0.0452891 9.92125 0.125855L3.19535 6.85175C1.52146 8.52564 0.599609 10.7546 0.599609 13.128C0.599609 15.5014 1.52146 17.7303 3.19535 19.4043C4.86929 21.0781 7.0982 22 9.47163 22C11.845 22 14.074 21.0781 15.7479 19.4043L22.4738 12.6784C22.5543 12.5978 22.5996 12.4885 22.5996 12.3745C22.5996 12.2605 22.5543 12.1513 22.4738 12.0707ZM10.2251 1.03735L12.1937 3.00601L10.6951 4.50463L8.72646 2.53602L10.2251 1.03735ZM15.1401 18.7966C13.6286 20.3081 11.6155 21.1406 9.47163 21.1406C7.47203 21.1406 5.58635 20.4162 4.1151 19.0922L6.08809 17.1192C7.06073 17.945 8.26596 18.3581 9.47167 18.358C10.8114 18.3579 12.1516 17.8478 13.1716 16.8279L17.4873 12.5122L19.456 14.4809L15.1401 18.7966ZM20.0636 13.8732L18.0949 11.9045L19.5936 10.4058L21.5623 12.3745L20.0636 13.8732Z"
        fill="#17172E"
      />
      <path
        d="M8.20374 8.03369C8.44105 8.03369 8.63342 7.84131 8.63342 7.604C8.63342 7.36669 8.44105 7.17432 8.20374 7.17432C7.96643 7.17432 7.77405 7.36669 7.77405 7.604C7.77405 7.84131 7.96643 8.03369 8.20374 8.03369Z"
        fill="#17172E"
      />
    </svg>
  );
};
