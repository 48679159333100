import { FC } from "react";

export const Close: FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M7.49589 7.99508C7.55163 7.93914 7.61787 7.89476 7.6908 7.86448C7.76373 7.83419 7.84192 7.8186 7.92089 7.8186C7.99986 7.8186 8.07805 7.83419 8.15098 7.86448C8.22391 7.89476 8.29015 7.93914 8.34589 7.99508L11.5199 11.1711L14.6959 7.99508C14.8086 7.88236 14.9615 7.81904 15.1209 7.81904C15.2803 7.81904 15.4332 7.88236 15.5459 7.99508C15.6586 8.10779 15.7219 8.26067 15.7219 8.42008C15.7219 8.57948 15.6586 8.73236 15.5459 8.84508L12.3689 12.0191L15.5449 15.1951C15.6571 15.308 15.7201 15.4608 15.7201 15.6201C15.7201 15.7793 15.6571 15.9321 15.5449 16.0451C15.4891 16.101 15.4229 16.1454 15.35 16.1757C15.277 16.206 15.1989 16.2215 15.1199 16.2215C15.0409 16.2215 14.9627 16.206 14.8898 16.1757C14.8169 16.1454 14.7506 16.101 14.6949 16.0451L11.5199 12.8681L8.34489 16.0441C8.23217 16.1568 8.07929 16.2201 7.91989 16.2201C7.76048 16.2201 7.60761 16.1568 7.49489 16.0441C7.38217 15.9314 7.31885 15.7785 7.31885 15.6191C7.31885 15.4597 7.38217 15.3068 7.49489 15.1941L10.6719 12.0191L7.49589 8.84408C7.43995 8.78833 7.39557 8.7221 7.36529 8.64917C7.335 8.57624 7.31942 8.49805 7.31942 8.41908C7.31942 8.34011 7.335 8.26192 7.36529 8.18899C7.39557 8.11605 7.43995 8.05082 7.49589 7.99508Z"
          fill="#17172E"
        />
      </g>
    </svg>
  );
};
