import { FC } from "react";

export const ArrowBack: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5448 4.85494C15.6007 4.91068 15.6451 4.97692 15.6754 5.04985C15.7057 5.12278 15.7213 5.20097 15.7213 5.27994C15.7213 5.35891 15.7057 5.4371 15.6754 5.51003C15.6451 5.58296 15.6007 5.6492 15.5448 5.70494L8.76981 12.4799L15.5458 19.2549C15.6585 19.3677 15.7219 19.5205 15.7219 19.6799C15.7219 19.8393 15.6585 19.9922 15.5458 20.1049C15.4331 20.2177 15.2802 20.281 15.1208 20.281C14.9614 20.281 14.8085 20.2177 14.6958 20.1049L7.49581 12.9049C7.43987 12.8492 7.39549 12.783 7.36521 12.71C7.33492 12.6371 7.31934 12.5589 7.31934 12.4799C7.31934 12.401 7.33492 12.3228 7.36521 12.2498C7.39549 12.1769 7.43987 12.1107 7.49581 12.0549L14.6958 4.85494C14.7516 4.799 14.8178 4.75462 14.8907 4.72434C14.9636 4.69406 15.0418 4.67847 15.1208 4.67847C15.1998 4.67847 15.278 4.69406 15.3509 4.72434C15.4238 4.75462 15.4891 4.799 15.5448 4.85494Z"
        fill="black"
      />
    </svg>
  );
};
