import { FC } from "react";

export const RenderIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M9.60293 8.99995C9.60293 9.23633 9.55637 9.4704 9.46591 9.68878C9.37545 9.90717 9.24287 10.1056 9.07572 10.2727C8.90858 10.4399 8.71015 10.5725 8.49176 10.6629C8.27337 10.7534 8.03931 10.8 7.80293 10.8C7.56655 10.8 7.33249 10.7534 7.1141 10.6629C6.89571 10.5725 6.69728 10.4399 6.53014 10.2727C6.36299 10.1056 6.23041 9.90717 6.13995 9.68878C6.04949 9.4704 6.00293 9.23633 6.00293 8.99995C6.00293 8.52256 6.19257 8.06472 6.53014 7.72716C6.8677 7.38959 7.32554 7.19995 7.80293 7.19995C8.28032 7.19995 8.73816 7.38959 9.07572 7.72716C9.41329 8.06472 9.60293 8.52256 9.60293 8.99995Z"
          fill="#17172E"
        />
        <path
          id="Vector_2"
          d="M4.1999 4.80005C3.72251 4.80005 3.26468 4.98969 2.92711 5.32726C2.58954 5.66482 2.3999 6.12266 2.3999 6.60005V17.4C2.3999 17.8774 2.58954 18.3353 2.92711 18.6728C3.26468 19.0104 3.72251 19.2 4.1999 19.2H19.7999C20.2773 19.2 20.7351 19.0104 21.0727 18.6728C21.4103 18.3353 21.5999 17.8774 21.5999 17.4V6.60005C21.5999 6.12266 21.4103 5.66482 21.0727 5.32726C20.7351 4.98969 20.2773 4.80005 19.7999 4.80005H4.1999ZM19.7999 6.00005C19.959 6.00005 20.1116 6.06326 20.2242 6.17578C20.3367 6.28831 20.3999 6.44092 20.3999 6.60005V13.8L15.8699 11.463C15.7574 11.4068 15.63 11.3874 15.5058 11.4075C15.3817 11.4276 15.2669 11.4862 15.1779 11.575L10.7259 16.027L7.5339 13.901C7.4187 13.8243 7.28049 13.7897 7.1427 13.8032C7.00491 13.8167 6.87603 13.8774 6.7779 13.975L3.6029 16.8V17.448C3.60127 17.4321 3.60027 17.4161 3.5999 17.4V6.60005C3.5999 6.52126 3.61542 6.44323 3.64557 6.37044C3.67573 6.29764 3.71992 6.2315 3.77564 6.17578C3.83135 6.12007 3.8975 6.07587 3.97029 6.04572C4.04309 6.01557 4.12111 6.00005 4.1999 6.00005H19.7999Z"
          fill="#17172E"
        />
      </g>
    </svg>
  );
};
