import { FC } from "react";

export const RightClick: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1169_233)">
        <path d="M12 5.5H17V11H12V5.5Z" fill="#37CC8F" />
        <path
          d="M17.58 1.94278e-07H6.42C5.57685 -0.000207247 4.74193 0.165709 3.96293 0.488271C3.18392 0.810833 2.47611 1.28372 1.87991 1.87991C1.28372 2.47611 0.810833 3.18392 0.488271 3.96293C0.165709 4.74193 -0.000207247 5.57685 1.94278e-07 6.42V17.58C-0.000207247 18.4231 0.165709 19.2581 0.488271 20.0371C0.810833 20.8161 1.28372 21.5239 1.87991 22.1201C2.47611 22.7163 3.18392 23.1892 3.96293 23.5117C4.74193 23.8343 5.57685 24.0002 6.42 24H17.58C18.4231 24.0002 19.2581 23.8343 20.0371 23.5117C20.8161 23.1892 21.5239 22.7163 22.1201 22.1201C22.7163 21.5239 23.1892 20.8161 23.5117 20.0371C23.8343 19.2581 24.0002 18.4231 24 17.58V6.42C24.0002 5.57685 23.8343 4.74193 23.5117 3.96293C23.1892 3.18392 22.7163 2.47611 22.1201 1.87991C21.5239 1.28372 20.8161 0.810833 20.0371 0.488271C19.2581 0.165709 18.4231 -0.000207247 17.58 1.94278e-07ZM7.56632 10.0184C7.56611 9.49079 7.66985 8.96829 7.87163 8.48076C8.0734 7.99323 8.36924 7.55023 8.74226 7.17706C9.11528 6.8039 9.55817 6.50788 10.0456 6.30591C10.5331 6.10395 11.0555 6 11.5832 6H11.7411V10.5679H7.56632V10.0184ZM16.4337 13.9816C16.4339 14.5092 16.3301 15.0317 16.1284 15.5192C15.9266 16.0068 15.6308 16.4498 15.2577 16.8229C14.8847 17.1961 14.4418 17.4921 13.9544 17.6941C13.4669 17.896 12.9445 18 12.4168 18H11.5832C11.0555 18 10.5331 17.896 10.0456 17.6941C9.55817 17.4921 9.11528 17.1961 8.74226 16.8229C8.36924 16.4498 8.0734 16.0068 7.87163 15.5192C7.66985 15.0317 7.56611 14.5092 7.56632 13.9816V11.3289H16.4337V13.9816ZM16.4337 10.5679H12.4974V6C13.5486 6.02148 14.5494 6.4541 15.2853 7.20504C16.0212 7.95599 16.4335 8.96544 16.4337 10.0168V10.5679Z"
          fill="#17172E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1169_233">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
