import { FC } from "react";

export const ArrowDropdown: FC<any> = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M11.577 16.248L5.82196 9.66896C5.14196 8.89496 5.69396 7.67896 6.72496 7.67896H18.235C18.4659 7.67848 18.6921 7.74466 18.8864 7.86955C19.0806 7.99444 19.2347 8.17274 19.3302 8.38305C19.4256 8.59336 19.4584 8.82675 19.4244 9.05519C19.3905 9.28364 19.2914 9.49745 19.139 9.67096L13.384 16.247C13.2715 16.3761 13.1327 16.4796 12.9768 16.5506C12.821 16.6215 12.6517 16.6583 12.4805 16.6583C12.3092 16.6583 12.14 16.6215 11.9841 16.5506C11.8282 16.4796 11.6894 16.3771 11.577 16.248Z"
          fill="#17172E"
        />
      </g>
    </svg>
  );
};
