import { FC } from "react";

export const BackgroundIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_152_418)">
        <path
          d="M11.8362 4.48852C11.8362 5.18633 11.2681 5.75395 10.5703 5.75395C9.87249 5.75395 9.30444 5.18633 9.30444 4.48852C9.30444 3.79027 9.87249 3.22266 10.5703 3.22266C11.2681 3.22266 11.8362 3.79027 11.8362 4.48852Z"
          fill="#17172E"
        />
        <path
          d="M18.2794 0H3.72071C1.98004 0 0.984458 1.18937 1.08543 1.09398C0.841802 1.34277 0.836646 1.74797 1.09016 2.00191L10.4346 11.3463L15.8748 5.90563C16.1254 5.65512 16.5336 5.65297 16.7862 5.90563L22 11.119V3.72066C22 1.66891 20.3311 0 18.2794 0ZM10.5704 7.04301C9.16184 7.04301 8.01543 5.89703 8.01543 4.48852C8.01543 3.07957 9.16184 1.93359 10.5704 1.93359C11.9789 1.93359 13.1253 3.07957 13.1253 4.48852C13.1253 5.89703 11.9789 7.04301 10.5704 7.04301Z"
          fill="#17172E"
        />
        <path
          d="M16.3308 7.27295L22 12.9427V18.2794C22 19.2737 21.6129 20.2078 20.9099 20.9104C20.6603 21.1596 20.2529 21.1643 19.9986 20.9104L11.3459 12.2578L16.3308 7.27295Z"
          fill="#17172E"
        />
        <path
          d="M17.1185 21.8112L0.188783 4.88153C-0.0629277 4.62982 -0.0629277 4.22174 0.188783 3.97003C0.440451 3.71832 0.848568 3.71832 1.10028 3.97003L18.03 20.8997C18.2817 21.1514 18.2817 21.5595 18.03 21.8112C17.7783 22.0629 17.3701 22.0629 17.1185 21.8112Z"
          fill="#17172E"
        />
        <path
          d="M13.3372 21.8112L0.188783 8.66278C-0.0629277 8.41107 -0.0629277 8.00299 0.188783 7.75128C0.440451 7.49957 0.848568 7.49957 1.10028 7.75128L14.2487 20.8997C14.5004 21.1514 14.5004 21.5595 14.2487 21.8112C13.997 22.0629 13.5889 22.0629 13.3372 21.8112Z"
          fill="#17172E"
        />
        <path
          d="M9.55597 21.8112L0.188783 12.444C-0.0629277 12.1923 -0.0629277 11.7842 0.188783 11.5325C0.440451 11.2808 0.848568 11.2808 1.10028 11.5325L10.4675 20.8997C10.7192 21.1514 10.7192 21.5595 10.4675 21.8112C10.2158 22.0629 9.80764 22.0629 9.55597 21.8112Z"
          fill="#17172E"
        />
        <path
          d="M5.77472 21.8112L0.188783 16.2253C-0.0629277 15.9736 -0.0629277 15.5655 0.188783 15.3138C0.440451 15.0621 0.848568 15.0621 1.10028 15.3138L6.68622 20.8997C6.93793 21.1514 6.93793 21.5595 6.68622 21.8112C6.43455 22.0629 6.02639 22.0629 5.77472 21.8112Z"
          fill="#17172E"
        />
        <path
          d="M1.99347 21.8112L0.188783 20.0065C-0.0629277 19.7548 -0.0629277 19.3467 0.188783 19.095C0.440451 18.8433 0.848568 18.8433 1.10028 19.095L2.90497 20.8997C3.15668 21.1514 3.15668 21.5595 2.90497 21.8112C2.6533 22.0629 2.24514 22.0629 1.99347 21.8112Z"
          fill="#17172E"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_418">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
