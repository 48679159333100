import { FC, useEffect, useRef } from "react";
import s from "./RenderPreview.module.scss";
import { useDispatch } from "react-redux";
import { handleImage } from "../../api/renderAndLoadImage";
import { Close } from "../../../assets/svg/Close";
type RenderPreviewT = {
  closeFunc: any;
  img: any;
};
export const RenderPreview: FC<RenderPreviewT> = ({ ...props }) => {
  const { closeFunc, img } = props;
  const wrapperRef: any = useRef(null);

  const dispatch = useDispatch();
  function clickHandler(type: "preview" | "download") {
    handleImage({ base64Image: img, type });
  }
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);
  //@ts-ignore

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>Download & preview</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.img}>
        <img src={`${img}`} alt="" />
      </div>
      <div className={s.btnWrapper}>
        <div className={s.timer}>
          {`Rendering time: 
          ${
            //@ts-ignore
            window.timer.m
          }m 
          ${
            //@ts-ignore
            window.timer.s
          }sec
          `}
        </div>
        <div className={s.btns}>
          <div className={s.download} onClick={() => clickHandler("download")}>
            Download
          </div>
          <div className={s.preview} onClick={() => clickHandler("preview")}>
            Preview
          </div>
        </div>
      </div>
    </div>
  );
};
