import { Close } from "../../../../assets/svg/Close";
import { exitHandler } from "../../../InterfaceWidgets/InterfaceWidgets";
import s from "./CloseFullWindow.module.scss";
import { FC } from "react";
export const CloseFullWindow: FC = () => {
  return (
    <div
      id="closeFull"
      className={`${s.closeFull}`}
      style={{
        position: "absolute",
        right: "50px",
        top: "50px",
        zIndex: 999,
        cursor: "pointer",
      }}
      onClick={() => {
        var doc = window.document;

        var cancelFullScreen =
          doc.exitFullscreen ||
          //@ts-ignore
          doc.mozCancelFullScreen ||
          //@ts-ignore
          doc.webkitExitFullscreen ||
          //@ts-ignore
          doc.msExitFullscreen;
        // document.exitFullscreen();
        cancelFullScreen.call(doc);
        exitHandler();
      }}
    >
      <Close />
    </div>
  );
};
