import s from "./Checkbox.module.scss";
import { FC, useState, useEffect } from "react";

type CheckboxT = {
  isActive?: boolean;
  setIfActive: any;
  setIfDisable: any;
};
export const Checkbox: FC<CheckboxT> = ({ ...props }) => {
  const { isActive = false, setIfActive, setIfDisable } = props;
  const [active, setActive] = useState(isActive);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (isActive !== active) {
      setActive(isActive);
    }
  }, [isActive]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      if (active) {
        setIfActive();
      } else {
        setIfDisable();
      }
    }
  }, [active]);
  return (
    <div
      className={`${s.checkbox} ${active && s.active}`}
      onClick={() => setActive(!active)}
    >
      <div className={s.point}></div>
    </div>
  );
};
