import { ArrowDropdown } from "../../assets/svg/ArrowDropdown";
import { CameraIcon } from "../../assets/svg/CameraIcon";
import { cameraList, cameraListT } from "../../utils/constants";
import { setCameraPosition } from "../../utils/functions/playcanvas/setCameraPosition";
import s from "./CameraDropdown.module.scss";
import { FC, useState, useEffect } from "react";

type CameraDropdownT = {
  setActiveCamera: any;
  activeCamera: any;
};
export const CameraDropdown: FC<CameraDropdownT> = ({ ...props }) => {
  const { setActiveCamera, activeCamera } = props;
  const [isOpen, setIsOpen] = useState(false);
  function changeCamera(camera: cameraListT) {
    setActiveCamera(camera);
    setIsOpen(!isOpen);
    setCameraPosition({ json: camera.value });
  }
  return (
    <div className={`${s.camera} ${isOpen && s.active}`}>
      <div className={s.title} onClick={() => setIsOpen(!isOpen)}>
        <CameraIcon />
        <span>{activeCamera.label}</span>
        <ArrowDropdown className={s.dropdown} />
      </div>
      <div className={s.content}>
        {cameraList.map((camera: cameraListT, index: number) => {
          return (
            <div
              className={`${s.item} ${
                activeCamera.id === camera.id && s.active
              }`}
              key={`camera_${index}`}
              onClick={() => changeCamera(camera)}
            >
              {camera.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};
