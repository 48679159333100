import { FC } from "react";

export const Reset: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icons_set">
        <path
          id="Vector"
          d="M16.7199 10.32H21.4389C21.4959 10.3201 21.5517 10.3364 21.5997 10.367C21.6478 10.3976 21.6861 10.4413 21.7102 10.4929C21.7343 10.5445 21.7433 10.6019 21.736 10.6584C21.7286 10.7149 21.7054 10.7682 21.6689 10.812L19.3099 13.644C19.2818 13.6776 19.2466 13.7046 19.2069 13.7232C19.1671 13.7417 19.1238 13.7513 19.0799 13.7513C19.0361 13.7513 18.9928 13.7417 18.953 13.7232C18.9133 13.7046 18.8781 13.6776 18.8499 13.644L16.4899 10.812C16.4535 10.7682 16.4302 10.7149 16.4229 10.6584C16.4156 10.6019 16.4246 10.5445 16.4487 10.4929C16.4728 10.4413 16.5111 10.3976 16.5592 10.367C16.6072 10.3364 16.663 10.3201 16.7199 10.32ZM3.51995 12.72H8.23995C8.29692 12.7199 8.35268 12.7035 8.40072 12.6729C8.44876 12.6423 8.48708 12.5986 8.51121 12.547C8.53533 12.4954 8.54426 12.438 8.53696 12.3815C8.52965 12.325 8.50641 12.2717 8.46995 12.228L6.10995 9.39596C6.08179 9.36234 6.0466 9.3353 6.00686 9.31674C5.96713 9.29819 5.9238 9.28857 5.87995 9.28857C5.83609 9.28857 5.79277 9.29819 5.75303 9.31674C5.71329 9.3353 5.6781 9.36234 5.64995 9.39596L3.28995 12.228C3.25349 12.2717 3.23024 12.325 3.22294 12.3815C3.21563 12.438 3.22456 12.4954 3.24869 12.547C3.27282 12.5986 3.31114 12.6423 3.35918 12.6729C3.40721 12.7035 3.46298 12.7199 3.51995 12.72Z"
          fill="#17172E"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4799 5.52006C11.5953 5.51891 10.7215 5.71382 9.92127 6.09076C9.12104 6.46771 8.4143 7.01732 7.85188 7.70006C7.80297 7.76433 7.74157 7.81806 7.67139 7.85804C7.60121 7.89801 7.52367 7.9234 7.44344 7.93269C7.3632 7.94198 7.28192 7.93497 7.20446 7.91209C7.127 7.88921 7.05495 7.85092 6.99265 7.79953C6.93034 7.74813 6.87905 7.68468 6.84185 7.61299C6.80466 7.54129 6.78232 7.46283 6.77618 7.38229C6.77003 7.30175 6.78022 7.22081 6.80611 7.1443C6.83201 7.0678 6.87309 6.99731 6.92688 6.93706C7.80559 5.87342 8.97131 5.08455 10.2854 4.66429C11.5995 4.24403 13.0066 4.21006 14.3395 4.56641C15.6723 4.92277 16.8747 5.65446 17.8037 6.67445C18.7328 7.69445 19.3492 8.95982 19.5799 10.3201H18.3599C18.0827 8.96508 17.3462 7.74736 16.2748 6.87277C15.2034 5.99817 13.8629 5.52034 12.4799 5.52006ZM6.59988 12.7201C6.82688 13.8298 7.36329 14.8527 8.14709 15.6704C8.9309 16.4882 9.93012 17.0674 11.0293 17.3412C12.1284 17.6151 13.2826 17.5723 14.3584 17.2178C15.4342 16.8633 16.3878 16.2116 17.1089 15.3381C17.1578 15.2738 17.2192 15.2201 17.2894 15.1801C17.3596 15.1401 17.4371 15.1147 17.5173 15.1054C17.5976 15.0961 17.6788 15.1031 17.7563 15.126C17.8338 15.1489 17.9058 15.1872 17.9681 15.2386C18.0304 15.29 18.0817 15.3534 18.1189 15.4251C18.1561 15.4968 18.1784 15.5753 18.1846 15.6558C18.1907 15.7364 18.1805 15.8173 18.1546 15.8938C18.1288 15.9703 18.0877 16.0408 18.0339 16.1011C17.1554 17.165 15.9898 17.9542 14.6757 18.3747C13.3616 18.7953 11.9543 18.8295 10.6213 18.4734C9.28836 18.1172 8.08574 17.3856 7.15654 16.3657C6.22733 15.3458 5.61066 14.0804 5.37988 12.7201H6.59988Z"
          fill="#17172E"
        />
      </g>
    </svg>
  );
};
