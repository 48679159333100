import { FC } from "react";

export const WASD: FC = () => {
  return (
    <svg
      width="108"
      height="24"
      viewBox="0 0 108 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0C2.91016 0 0 2.91016 0 6.5V17.5C0 21.0898 2.91016 24 6.5 24H17.5C21.0898 24 24 21.0898 24 17.5V6.5C24 2.91016 21.0898 0 17.5 0H6.5ZM15.64 16.5L17.836 8.07593H15.628L14.404 14.208L12.964 8.07593H10.708L9.20798 14.208L7.996 8.07593H5.79999L7.93597 16.5H10.42L11.812 10.9558L13.156 16.5H15.64Z"
        fill="#17172E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.5 0C58.9102 0 56 2.91016 56 6.5V17.5C56 21.0898 58.9102 24 62.5 24H73.5C77.0898 24 80 21.0898 80 17.5V6.5C80 2.91016 77.0898 0 73.5 0H62.5ZM66.636 16.2839C67.124 16.4841 67.676 16.584 68.292 16.584C68.924 16.584 69.472 16.468 69.936 16.2361C70.4 16.0042 70.752 15.6921 70.992 15.3C71.24 14.908 71.364 14.4722 71.364 13.9919C71.364 13.488 71.24 13.0762 70.992 12.7561C70.744 12.436 70.448 12.1921 70.104 12.0242C69.768 11.856 69.328 11.6802 68.784 11.4961C68.24 11.3201 67.84 11.156 67.584 11.0042C67.328 10.8521 67.2 10.6401 67.2 10.3682C67.2 10.1201 67.276 9.93213 67.428 9.80396C67.58 9.66797 67.78 9.6001 68.028 9.6001C68.316 9.6001 68.552 9.68018 68.736 9.84009C68.92 9.99194 69.02 10.2041 69.036 10.4761H71.256C71.216 9.68408 70.912 9.06812 70.344 8.62817C69.776 8.18018 69.028 7.95605 68.1 7.95605C67.188 7.95605 66.448 8.18018 65.88 8.62817C65.312 9.06812 65.028 9.68018 65.028 10.4641C65.028 10.9919 65.152 11.4202 65.4 11.748C65.648 12.0762 65.944 12.324 66.288 12.4919C66.4704 12.5811 66.6808 12.6702 66.9194 12.7593C67.1309 12.8384 67.3644 12.9172 67.62 12.9961C67.996 13.1082 68.284 13.208 68.484 13.2961C68.692 13.384 68.864 13.4961 69 13.6321C69.144 13.7681 69.216 13.9399 69.216 14.1479C69.216 14.396 69.128 14.592 68.952 14.7361C68.776 14.8721 68.536 14.9399 68.232 14.9399C67.936 14.9399 67.696 14.8601 67.512 14.7C67.328 14.532 67.22 14.292 67.188 13.98H65.004C65.02 14.532 65.172 15.0042 65.46 15.396C65.756 15.7881 66.148 16.084 66.636 16.2839Z"
        fill="#17172E"
      />
      <path
        d="M39.916 10.344L40.96 13.428H38.884L39.916 10.344Z"
        fill="#17172E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5 0C30.9102 0 28 2.91016 28 6.5V17.5C28 21.0898 30.9102 24 34.5 24H45.5C49.0898 24 52 21.0898 52 17.5V6.5C52 2.91016 49.0898 0 45.5 0H34.5ZM37.84 16.5L38.344 15.012H41.488L41.992 16.5H44.164L41.116 8.07593H38.74L35.692 16.5H37.84Z"
        fill="#17172E"
      />
      <path
        d="M97.08 14.0879C96.648 14.512 96.044 14.7239 95.268 14.7239H94.296V9.82788H95.268C96.044 9.82788 96.648 10.0439 97.08 10.4758C97.512 10.908 97.728 11.512 97.728 12.2878C97.728 13.064 97.512 13.6638 97.08 14.0879Z"
        fill="#17172E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.5 0C86.9102 0 84 2.91016 84 6.5V17.5C84 21.0898 86.9102 24 90.5 24H101.5C105.09 24 108 21.0898 108 17.5V6.5C108 2.91016 105.09 0 101.5 0H90.5ZM97.728 8.604C97.064 8.25195 96.288 8.07593 95.4 8.07593H92.244V16.5H95.4C96.28 16.5 97.052 16.324 97.716 15.9719C98.388 15.6199 98.904 15.124 99.264 14.4839C99.632 13.844 99.816 13.1118 99.816 12.2878C99.816 11.4558 99.632 10.7239 99.264 10.092C98.904 9.4519 98.392 8.95581 97.728 8.604Z"
        fill="#17172E"
      />
    </svg>
  );
};
