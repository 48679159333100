import { FC } from "react";

export const Accept: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M10.7269 5.18768C10.843 5.06922 11.0014 5.00172 11.1672 5.00003C11.333 4.99834 11.4928 5.06261 11.6112 5.17868C11.7297 5.29476 11.7972 5.45314 11.7989 5.61899C11.8006 5.78483 11.7363 5.94455 11.6202 6.06302L8.2929 10.221C8.23575 10.2826 8.16675 10.3321 8.09004 10.3664C8.01332 10.4008 7.93047 10.4193 7.84644 10.4208C7.76241 10.4224 7.67893 10.407 7.601 10.3755C7.52307 10.344 7.45229 10.2971 7.3929 10.2377L5.1889 8.03302C5.12962 7.9753 5.0824 7.90638 5.04997 7.83027C5.01755 7.75415 5.00057 7.67236 5.00001 7.58962C4.99946 7.50689 5.01535 7.42487 5.04676 7.34833C5.07816 7.27179 5.12446 7.20225 5.18296 7.14375C5.24146 7.08525 5.311 7.03895 5.38754 7.00754C5.46409 6.97614 5.5461 6.96025 5.62884 6.9608C5.71157 6.96135 5.79337 6.97833 5.86948 7.01076C5.9456 7.04318 6.01451 7.09041 6.07223 7.14968L7.8169 8.89368L10.7122 5.20635C10.7169 5.19968 10.7216 5.19302 10.7276 5.18768H10.7269Z"
        fill="#17172E"
      />
    </svg>
  );
};
