import { FC } from "react";

export const Save: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M3.00002 14.1001C3.15915 14.1001 3.31177 14.1633 3.42429 14.2758C3.53681 14.3884 3.60002 14.541 3.60002 14.7001V17.7001C3.60002 18.0184 3.72645 18.3236 3.9515 18.5486C4.17654 18.7737 4.48176 18.9001 4.80002 18.9001H19.2C19.5183 18.9001 19.8235 18.7737 20.0486 18.5486C20.2736 18.3236 20.4 18.0184 20.4 17.7001V14.7001C20.4 14.541 20.4632 14.3884 20.5758 14.2758C20.6883 14.1633 20.8409 14.1001 21 14.1001C21.1592 14.1001 21.3118 14.1633 21.4243 14.2758C21.5368 14.3884 21.6 14.541 21.6 14.7001V17.7001C21.6 18.3366 21.3472 18.9471 20.8971 19.3972C20.447 19.8472 19.8365 20.1001 19.2 20.1001H4.80002C4.1635 20.1001 3.55306 19.8472 3.10297 19.3972C2.65288 18.9471 2.40002 18.3366 2.40002 17.7001V14.7001C2.40002 14.541 2.46324 14.3884 2.57576 14.2758C2.68828 14.1633 2.84089 14.1001 3.00002 14.1001Z"
          fill="#17172E"
        />
        <path
          id="Vector_2"
          d="M11.576 3.59493C11.6317 3.53899 11.698 3.49461 11.7709 3.46433C11.8438 3.43405 11.922 3.41846 12.001 3.41846C12.08 3.41846 12.1582 3.43405 12.2311 3.46433C12.304 3.49461 12.3702 3.53899 12.426 3.59493L16.026 7.19493C16.1387 7.30765 16.202 7.46052 16.202 7.61993C16.202 7.77934 16.1387 7.93221 16.026 8.04493C15.9133 8.15765 15.7604 8.22097 15.601 8.22097C15.4416 8.22097 15.2887 8.15765 15.176 8.04493L12.6 5.46793V16.0199C12.6 16.1791 12.5368 16.3317 12.4243 16.4442C12.3117 16.5567 12.1591 16.6199 12 16.6199C11.8409 16.6199 11.6882 16.5567 11.5757 16.4442C11.4632 16.3317 11.4 16.1791 11.4 16.0199V5.46793L8.82499 8.04493C8.76918 8.10074 8.70292 8.14501 8.63 8.17522C8.55708 8.20542 8.47892 8.22097 8.39999 8.22097C8.32106 8.22097 8.2429 8.20542 8.16998 8.17522C8.09706 8.14501 8.0308 8.10074 7.97499 8.04493C7.91918 7.98912 7.87491 7.92286 7.8447 7.84994C7.8145 7.77702 7.79895 7.69886 7.79895 7.61993C7.79895 7.541 7.8145 7.46284 7.8447 7.38992C7.87491 7.317 7.91918 7.25074 7.97499 7.19493L11.575 3.59493H11.576Z"
          fill="#17172E"
        />
      </g>
    </svg>
  );
};
