import { FC } from "react";

export const SortA: FC<any> = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.98518 6.42331L9.65052 7.51997H8.79785L10.2292 3.25464H11.2159L12.6412 7.51997H11.7452L11.4099 6.42331H9.98518ZM11.2412 5.79531L10.7199 4.06997H10.6819L10.1605 5.79531H11.2412Z"
          fill="#17172E"
        />
        <path
          id="Vector_2"
          d="M9.14249 13.12V12.5674L11.2058 9.59135V9.54802H9.19849V8.85469H12.2265V9.40802L10.1732 12.3834V12.4267H12.2892V13.12H9.14249ZM5.51982 3.92002C5.51982 3.81393 5.47768 3.71219 5.40266 3.63718C5.32765 3.56216 5.22591 3.52002 5.11982 3.52002C5.01374 3.52002 4.91199 3.56216 4.83698 3.63718C4.76196 3.71219 4.71982 3.81393 4.71982 3.92002V11.7547L3.80315 10.8367C3.75641 10.7904 3.69904 10.7564 3.63607 10.7374C3.5731 10.7185 3.50645 10.7154 3.44196 10.7282C3.37748 10.741 3.31712 10.7695 3.26619 10.811C3.21526 10.8526 3.1753 10.9061 3.14982 10.9667C3.11938 11.0399 3.11136 11.1204 3.12679 11.1982C3.14222 11.2759 3.1804 11.3473 3.23649 11.4034L4.84249 13.008C4.91762 13.0812 5.01859 13.1217 5.12345 13.1208C5.22831 13.12 5.32858 13.0777 5.40249 13.0034L7.00249 11.4034C7.0397 11.3662 7.06923 11.3221 7.0894 11.2736C7.10957 11.2251 7.11998 11.173 7.12004 11.1205C7.12011 11.0679 7.10982 11.0159 7.08976 10.9673C7.0697 10.9187 7.04028 10.8746 7.00316 10.8374C6.96603 10.8001 6.92195 10.7706 6.87342 10.7504C6.82488 10.7303 6.77285 10.7199 6.72029 10.7198C6.66774 10.7197 6.61568 10.73 6.5671 10.7501C6.51852 10.7701 6.47436 10.7996 6.43716 10.8367L5.51982 11.7554V3.92002Z"
          fill="#17172E"
        />
      </g>
    </svg>
  );
};
