import { FC } from "react";

export const SortZ: FC<any> = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.98518 6.42331L9.65052 7.51997H8.79785L10.2292 3.25464H11.2159L12.6412 7.51997H11.7452L11.4099 6.42331H9.98518ZM11.2412 5.79531L10.7199 4.06997H10.6819L10.1605 5.79531H11.2412Z"
          fill="#17172E"
        />
        <path
          id="Vector_2"
          d="M9.1425 13.12V12.5673L11.2058 9.59131V9.54797H9.1985V8.85464H12.2265V9.40731L10.1732 12.3833V12.4266H12.2892V13.12H9.1425ZM5.51983 12.72C5.51983 12.8261 5.47769 12.9278 5.40268 13.0028C5.32766 13.0778 5.22592 13.12 5.11983 13.12C5.01375 13.12 4.91201 13.0778 4.83699 13.0028C4.76198 12.9278 4.71983 12.8261 4.71983 12.72V4.88664L3.80317 5.80331C3.72802 5.87845 3.62611 5.92067 3.51983 5.92067C3.41356 5.92067 3.31165 5.87845 3.2365 5.80331C3.16136 5.72816 3.11914 5.62625 3.11914 5.51997C3.11914 5.4137 3.16136 5.31179 3.2365 5.23664L4.8425 3.63197C4.91772 3.55898 5.01865 3.51855 5.12346 3.51942C5.22827 3.5203 5.32851 3.56241 5.4025 3.63664L7.0025 5.23664C7.04956 5.28296 7.08437 5.34025 7.10381 5.40335C7.12325 5.46645 7.12672 5.53339 7.1139 5.59816C7.10108 5.66293 7.07237 5.72351 7.03035 5.77444C6.98833 5.82537 6.93432 5.86507 6.87317 5.88997C6.80007 5.92027 6.71963 5.92821 6.64202 5.91278C6.56441 5.89736 6.49312 5.85926 6.43717 5.80331L5.51983 4.88597V12.72Z"
          fill="#17172E"
        />
      </g>
    </svg>
  );
};
