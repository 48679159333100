import { FC } from "react";
import s from "./Interface.module.scss";
import { ComponentNavigator } from "../ComponentNavigator/ComponentNavigator";
import { InterfaceWidgets } from "../InterfaceWidgets/InterfaceWidgets";
import { Logo } from "../../assets/svg/Logo";
import { LogoWithText } from "../../assets/svg/LogoWithText";
import { ServerStatus } from "../../shared/ServerStatus/ServerStatus";
import { useSelector } from "react-redux";
import { getServerStatus } from "../../redux/selectors/selectors";

export const Interface: FC = () => {
  const serverStatus = useSelector(getServerStatus());
  return (
    <div className={s.wrapper}>
      <ServerStatus status={serverStatus} />
      <div className={s.titleWrapper}>
        <div className={s.title}>Select category</div>
        <LogoWithText />
      </div>
      <ComponentNavigator />
      <InterfaceWidgets />
    </div>
  );
};
