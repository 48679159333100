type dimensionsToggleT = {
  show: boolean;
};
export async function dimensionsToggle({ ...props }: dimensionsToggleT) {
  const { show } = props;
  //@ts-ignore
  const containerRef = window.containerRef;
  const canvasIframe = containerRef.current.contentWindow;
  canvasIframe.SetGridVisible(show);
}
