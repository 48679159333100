import { categoryDataT } from "../../utils/categoriesData";
import s from "./ImageItem.module.scss";
import { FC } from "react";
import test from "../../assets/images/test.png";
type ImageItemT = {
  item: categoryDataT;
  onClickFunc: any;
  isActive?: boolean
};
export const ImageItem: FC<ImageItemT> = ({ ...props }) => {
  const { item, onClickFunc, isActive } = props;

  return (
    <div className={`${s.wrapper} ${isActive && s.active}`} onClick={onClickFunc}>
      <div className={s.img}>
        <img src={item.imgCover ? item.imgCover : test} alt="" />
      </div>
      <div className={s.text}>{item.label}</div>
    </div>
  );
};
