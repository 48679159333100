import { FC } from "react";

export const CameraIcon: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.40002 8.63999C2.40002 8.00347 2.65288 7.39302 3.10297 6.94293C3.55306 6.49285 4.1635 6.23999 4.80002 6.23999H13.8C14.3823 6.23984 14.9447 6.45135 15.3826 6.83512C15.8204 7.21889 16.1038 7.74876 16.18 8.32599L19.912 6.66699C20.0947 6.58567 20.2948 6.55129 20.4942 6.56698C20.6935 6.58267 20.8858 6.64793 21.0535 6.75682C21.2212 6.86572 21.3591 7.0148 21.4545 7.19052C21.55 7.36624 21.6 7.56302 21.6 7.76299V16.717C21.5999 16.9168 21.5499 17.1135 21.4545 17.2891C21.3591 17.4647 21.2214 17.6137 21.0538 17.7225C20.8862 17.8314 20.6941 17.8967 20.4949 17.9125C20.2957 17.9283 20.0957 17.8941 19.913 17.813L16.18 16.155C16.1036 16.732 15.8201 17.2617 15.3823 17.6452C14.9444 18.0288 14.3821 18.2402 13.8 18.24H4.80002C4.1635 18.24 3.55306 17.9871 3.10297 17.537C2.65288 17.087 2.40002 16.4765 2.40002 15.84V8.63999ZM16.2 14.85L20.4 16.717V7.76299L16.2 9.62999V14.85ZM4.80002 7.43999C4.48176 7.43999 4.17654 7.56642 3.9515 7.79146C3.72645 8.01651 3.60002 8.32173 3.60002 8.63999V15.84C3.60002 16.1583 3.72645 16.4635 3.9515 16.6885C4.17654 16.9136 4.48176 17.04 4.80002 17.04H13.8C14.1183 17.04 14.4235 16.9136 14.6486 16.6885C14.8736 16.4635 15 16.1583 15 15.84V8.63999C15 8.32173 14.8736 8.01651 14.6486 7.79146C14.4235 7.56642 14.1183 7.43999 13.8 7.43999H4.80002Z"
          fill="#17172E"
        />
      </g>
    </svg>
  );
};
