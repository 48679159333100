import { FC } from "react";
import s from "./ServerStatus.module.scss";

type ServerStatusT = {
  status: "offline" | "online" | "pending";
};
export const ServerStatus: FC<ServerStatusT> = ({ status }) => {
  return (
    <div className={`${s.wrapper} ${s[status]}`}>
      <div className={s.label}>Server status</div>
      <div className={s.value}>{status}</div>
    </div>
  );
};
