type setCameraPositionT = {
  json: any;
};
export async function setCameraPosition({ ...props }: setCameraPositionT) {
  const { json } = props;
  console.log('json: ', json);
  //@ts-ignore
  const containerRef = window.containerRef;
  const scooterIframe = containerRef.current.contentWindow;
  scooterIframe.ApplyCameraPreset(JSON.stringify(json));
}
