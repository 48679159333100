import { Accept } from "../../assets/svg/Accept";
import { FilterIcon } from "../../assets/svg/FilterIcon";
import s from "./Filter.module.scss";
import { FC, useState } from "react";

export function removeNumberFromArray(number: number, array: any) {
  const newArray = array.filter((item: any) => item !== number);
  return newArray;
}

type FilterT = {
  filterValue: any;
  setFilterValue: any;
  filterData: any;
};
export const Filter: FC<FilterT> = ({ ...props }) => {
  const { filterData, filterValue, setFilterValue } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  function toggleAccount(id: any) {
    if (filterValue !== undefined && filterValue.includes(id)) {
      const newArrayAcc = removeNumberFromArray(id, filterValue);
      setFilterValue(newArrayAcc);
    } else {
      setFilterValue([...filterValue, id]);
    }
  }

  return (
    <div className={s.wrapper}>
      <div
        className={`${s.title} ${isOpen && s.active}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FilterIcon />
      </div>
      {isOpen && (
        <div className={s.content}>
          {filterData.map((item: any, index: number) => {
            return (
              <div
                className={`${s.item} ${
                  filterValue.includes(index) && s.active
                }`}
                onClick={() => {
                  toggleAccount(index);
                }}
                key={`filter_${index}`}
              >
                <div className={s.accept}>
                  <Accept />
                </div>
                <div className={s.text}>{item}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
