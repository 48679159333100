import s from "./Player.module.scss";
import { FC } from "react";
import { PlayCanvasIntegration } from "./components/PlayCanvasIntegration/PlayCanvasIntegration";
import { PlayerWidgets } from "../PlayerWidgets/PlayerWidgets";
import { PlayerSettings } from "../PlayerSettings/PlayerSettings";
import { CloseFullWindow } from "./components/CloseFullWindow/CloseFullWindow";
import { SceneHint } from "../../shared/SceneHint/SceneHint";
const Player: FC = () => {
  return (
    <div className={s.wrapper} id="playerContainer">
      <CloseFullWindow />
      <PlayCanvasIntegration />
      <PlayerWidgets />
      <PlayerSettings />
      <SceneHint />
    </div>
  );
};

export default Player;
