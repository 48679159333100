import { FC } from "react";

export const QE: FC = () => {
  return (
    <svg
      width="52"
      height="24"
      viewBox="0 0 52 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.552 13.2639C10.336 12.8398 10.228 12.3479 10.228 11.7878C10.228 11.228 10.336 10.74 10.552 10.324C10.768 9.8999 11.068 9.57593 11.452 9.35181C11.844 9.12793 12.292 9.01587 12.796 9.01587C13.3 9.01587 13.744 9.12793 14.128 9.35181C14.512 9.57593 14.812 9.8999 15.028 10.324C15.244 10.74 15.352 11.228 15.352 11.7878C15.352 12.3479 15.244 12.8398 15.028 13.2639C14.812 13.688 14.512 14.0159 14.128 14.2478C13.744 14.4719 13.3 14.584 12.796 14.584C12.292 14.584 11.844 14.4719 11.452 14.2478C11.068 14.0159 10.768 13.688 10.552 13.2639Z"
        fill="#17172E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0C3.18628 0 0.5 2.68628 0.5 6V18C0.5 21.3137 3.18628 24 6.5 24H18.5C21.8137 24 24.5 21.3137 24.5 18V6C24.5 2.68628 21.8137 0 18.5 0H6.5ZM13.876 15.9519L15.148 17.4758H17.236L15.316 15.3159C15.86 14.9319 16.288 14.4358 16.6 13.8279C16.92 13.2119 17.08 12.532 17.08 11.7878C17.08 10.9719 16.888 10.24 16.504 9.5918C16.128 8.93579 15.612 8.42383 14.956 8.05591C14.308 7.68799 13.588 7.50391 12.796 7.50391C12.012 7.50391 11.292 7.68799 10.636 8.05591C9.97998 8.42383 9.46002 8.93579 9.07599 9.5918C8.69202 10.24 8.5 10.9719 8.5 11.7878C8.5 12.6118 8.69202 13.3518 9.07599 14.0078C9.46002 14.656 9.97998 15.1638 10.636 15.532C11.292 15.8999 12.012 16.084 12.796 16.084C13.164 16.084 13.524 16.0398 13.876 15.9519Z"
        fill="#17172E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5 0C30.1863 0 27.5 2.68628 27.5 6V18C27.5 21.3137 30.1863 24 33.5 24H45.5C48.8137 24 51.5 21.3137 51.5 18V6C51.5 2.68628 48.8137 0 45.5 0H33.5ZM39.18 11.468V9.36792H42.36V8H37.5V16.3879H42.36V15.02H39.18V12.8H42V11.468H39.18Z"
        fill="#17172E"
      />
    </svg>
  );
};
