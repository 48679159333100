import { FC } from "react";

export const RoomIcon: FC = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1109 21.9272L18.7775 16.9438C18.8887 16.833 18.8887 16.6115 18.7775 16.6115L10.1109 11.6281C9.99978 11.6281 9.99977 11.6281 9.88866 11.6281L1.22201 16.5008C1.11089 16.6115 1.11089 16.8331 1.22201 16.8331L9.88866 21.8165C9.99977 22.038 9.99978 22.038 10.1109 21.9272Z"
        fill="#17172E"
      />
      <path
        d="M0 5.20537V14.84C0 15.0615 0.222226 15.1722 0.333337 15.0614L3.22222 13.4003C3.33333 13.4003 3.33333 13.2896 3.33333 13.1788V7.53096C3.33333 7.42021 3.33334 7.42013 3.44445 7.30939L6.44445 5.64824C6.55556 5.64824 6.55555 5.64835 6.55555 5.75909V11.0747C6.55555 11.2962 6.77777 11.407 6.88889 11.2963L8.66667 10.2995C8.77778 10.2995 8.77777 10.1887 8.77777 10.078V0.443354C8.77777 0.221867 8.55556 0.111178 8.44445 0.221922L0 5.20537C0 5.09463 0 5.09463 0 5.20537Z"
        fill="#17172E"
      />
      <path
        d="M11.1111 0.332284V10.0776L19.6666 15.0611C19.7777 15.1718 20 15.0611 20 14.8396V5.20502C20 5.09428 20 5.0942 19.8889 4.98345L11.4444 0C11.3333 0 11.1111 0.110797 11.1111 0.332284Z"
        fill="#17172E"
      />
    </svg>
  );
};
