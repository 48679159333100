import { SearchIcon } from "../../assets/svg/SearchIcon";
import { categoryDataT } from "../../utils/categoriesData";
import { categoryesListT } from "../../utils/constants";
import s from "./Search.module.scss";
import { FC, useState, useEffect, useId } from "react";

type SearchT = {
  setValue: any;
  value: string;
};
export const Search: FC<SearchT> = ({ ...props }) => {
  const { value, setValue } = props;
  const searchId = useId();

  function changeValue(value: any) {
    setValue(value);
  }
  return (
    <div className={s.wrapper}>
      <label htmlFor={searchId}>
        <SearchIcon />
      </label>
      <input
        type="text"
        name="search"
        value={value}
        onChange={(e) => changeValue(e.target.value)}
        id={searchId}
        placeholder="Enter item name"
      />
    </div>
  );
};
