import { FC } from "react";

export const FloorIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_152_410)">
        <path
          d="M16.1694 21.1406L21.1407 16.1688V10.1864L10.1865 21.1406H16.1694Z"
          fill="#17172E"
        />
        <path
          d="M12.2375 0.859375L0.859375 12.2375V17.3475L17.3475 0.859375H12.2375Z"
          fill="#17172E"
        />
        <path
          d="M19.5938 21.1407C20.004 21.1402 20.3972 20.9771 20.6872 20.6871C20.9771 20.3971 21.1403 20.0039 21.1407 19.5938V16.655L16.6555 21.1407H19.5938Z"
          fill="#17172E"
        />
        <path
          d="M5.9082 0.859375L0.859375 5.62564V11.7514L11.7514 0.859375H5.9082Z"
          fill="#17172E"
        />
        <path
          d="M2.40625 0.859375C1.99613 0.85983 1.60294 1.02295 1.31295 1.31295C1.02295 1.60294 0.85983 1.99613 0.859375 2.40625V5.15294L5.40749 0.859375H2.40625Z"
          fill="#17172E"
        />
        <path
          d="M19.5938 0.859375H17.8337L0.859375 17.8336V19.5938C0.85983 20.0039 1.02295 20.3971 1.31295 20.6871C1.60294 20.9771 1.99613 21.1402 2.40625 21.1406H3.62355L21.1406 3.6236V2.40625C21.1402 1.99613 20.9771 1.60294 20.6871 1.31295C20.3971 1.02295 20.0039 0.85983 19.5938 0.859375Z"
          fill="#17172E"
        />
        <path
          d="M9.7002 21.1407L21.1405 9.70032V4.10974L4.10962 21.1407H9.7002Z"
          fill="#17172E"
        />
      </g>
      <defs>
        <clipPath id="clip0_152_410">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
