import { categoryId } from "../../utils/constants";
import { dimTypeT } from "../../utils/functions/playcanvas/playerSettings";
import {
  ModalStatusT,
  NameModals,
  envOptoinsT,
  modalsListT,
  stateT,
} from "../reducers/types";

export const getStateModal =
  (nameModal: NameModals) =>
  ({ ...state }: stateT) => {
    const modalList: modalsListT = state.ui.modalsList;
    const selectedModal: ModalStatusT = modalList[nameModal];
    return { name: nameModal, data: selectedModal };
  };

export const getActiveTab =
  () =>
  ({ ...state }: stateT) => {
    const activeTab: categoryId | undefined = state.ui.activeTab;
    return activeTab;
  };

export const getSelectedModel =
  () =>
  ({ ...state }: stateT) => {
    const selectedModel: boolean = state.ui.selectedModel;
    const selectedType: dimTypeT = state.ui.selectedType;
    return { selectedModel, selectedType };
  };

export const getWsStatus =
  () =>
  ({ ...state }: stateT) => {
    const wsConnected: boolean = state.ui.wsConnected;
    return wsConnected;
  };
export const getRenderHash =
  () =>
  ({ ...state }: stateT) => {
    const renderHash: any = state.ui.renderHash;
    return renderHash;
  };

export const getProducts =
  () =>
  ({ ...state }: stateT) => {
    const products: any = state.ui.products;
    return products;
  };
export const getEnvironments =
  () =>
  ({ ...state }: stateT) => {
    const environments: any = state.ui.environments;
    return environments;
  };

export const getEnvironmentVariant =
  (nameEnv: envOptoinsT) =>
  ({ ...state }: stateT) => {
    if (nameEnv) {
      const environmentVariant: any = state.ui.selectedVariants[nameEnv];
      return environmentVariant;
    }
  };

export const getCategories =
  () =>
  ({ ...state }: stateT) => {
    const categories: any = state.ui.categories;
    return categories;
  };
export const getServerStatus =
  () =>
  ({ ...state }: stateT) => {
    const serverStatus: any = state.ui.serverStatus;
    return serverStatus;
  };
