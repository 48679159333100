import { FC } from "react";

export const DeleteIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_960_4940)">
        <path
          d="M15.3602 8.69531C15.0497 8.69531 14.7981 8.94692 14.7981 9.25737V19.8803C14.7981 20.1905 15.0497 20.4423 15.3602 20.4423C15.6706 20.4423 15.9222 20.1905 15.9222 19.8803V9.25737C15.9222 8.94692 15.6706 8.69531 15.3602 8.69531Z"
          fill="white"
        />
        <path
          d="M8.72783 8.69531C8.41738 8.69531 8.16577 8.94692 8.16577 9.25737V19.8803C8.16577 20.1905 8.41738 20.4423 8.72783 20.4423C9.03828 20.4423 9.28989 20.1905 9.28989 19.8803V9.25737C9.28989 8.94692 9.03828 8.69531 8.72783 8.69531Z"
          fill="white"
        />
        <path
          d="M3.89419 7.14503V20.993C3.89419 21.8115 4.19432 22.5801 4.71862 23.1317C5.2405 23.6847 5.96678 23.9987 6.72688 24H17.3612C18.1215 23.9987 18.8478 23.6847 19.3695 23.1317C19.8938 22.5801 20.1939 21.8115 20.1939 20.993V7.14503C21.2361 6.86839 21.9115 5.86152 21.7721 4.79207C21.6324 3.72284 20.7215 2.923 19.643 2.92278H16.7653V2.22021C16.7686 1.62939 16.535 1.06206 16.1168 0.644685C15.6985 0.227532 15.1303 -0.00475635 14.5395 7.385e-05H9.54859C8.95777 -0.00475635 8.38957 0.227532 7.97132 0.644685C7.55306 1.06206 7.31946 1.62939 7.32275 2.22021V2.92278H4.44505C3.3666 2.923 2.45567 3.72284 2.31603 4.79207C2.17662 5.86152 2.85197 6.86839 3.89419 7.14503ZM17.3612 22.8759H6.72688C5.76589 22.8759 5.01831 22.0504 5.01831 20.993V7.19443H19.0698V20.993C19.0698 22.0504 18.3222 22.8759 17.3612 22.8759ZM8.44687 2.22021C8.44314 1.92754 8.55818 1.64585 8.76588 1.43925C8.97336 1.23265 9.25571 1.11914 9.54859 1.12419H14.5395C14.8324 1.11914 15.1147 1.23265 15.3222 1.43925C15.5299 1.64563 15.645 1.92754 15.6412 2.22021V2.92278H8.44687V2.22021ZM4.44505 4.0469H19.643C20.2018 4.0469 20.6548 4.49984 20.6548 5.05861C20.6548 5.61737 20.2018 6.07031 19.643 6.07031H4.44505C3.88629 6.07031 3.43335 5.61737 3.43335 5.05861C3.43335 4.49984 3.88629 4.0469 4.44505 4.0469Z"
          fill="white"
        />
        <path
          d="M12.044 8.69531C11.7335 8.69531 11.4819 8.94692 11.4819 9.25737V19.8803C11.4819 20.1905 11.7335 20.4423 12.044 20.4423C12.3544 20.4423 12.6061 20.1905 12.6061 19.8803V9.25737C12.6061 8.94692 12.3544 8.69531 12.044 8.69531Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_960_4940">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.0498047)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
