import React, { FC, useEffect } from "react";
import s from "./Timer.module.scss";
import { useRafLoop } from "react-use";

function formatTime(ms: any) {
  const totalMilliseconds = ms * 1.6; // Ускоряем время в 2 раза
  const totalSeconds = totalMilliseconds / 100;
  const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, "0");
  const seconds = String(Math.floor(totalSeconds % 60)).padStart(2, "0");

  const milliseconds = String(Math.floor(totalMilliseconds % 100)).padStart(
    2,
    "0"
  );

  return {
    ms: milliseconds,
    s: seconds,
    m: minutes,
  };
}

export const Timer: FC = () => {
  const [currentTime, setCurrentTime] = React.useState<number>(0);

  useRafLoop(() => {
    setCurrentTime((prevTime) => prevTime + 1);
  });

  useEffect(() => {
    //@ts-ignore
    window.stopwatch = true;

    setInterval(() => {
      setCurrentTime((prevTime: any) => {
        // Форматируем текущее время
        const formattedTime = formatTime(prevTime);
        //@ts-ignore
        window.timer = formattedTime;
        return prevTime;
      });
    }, 100);
    return () => {
      //@ts-ignore
      window.stopwatch = undefined;
    };
  }, []);

  // Форматируем текущее время
  const formattedTime = formatTime(currentTime);
  return (
    <div className={s.timer}>
      <span className={s.m}>{formattedTime.m}</span>:
      <span className={s.s}>{formattedTime.s}</span>:
      <span className={s.ms}>{formattedTime.ms}</span>
    </div>
  );
};
