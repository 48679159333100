import { useDispatch, useSelector } from "react-redux";
import {
  categoryId,
  categoryesListT,
  staticEnvironments,
} from "../../utils/constants";
import s from "./ImagePicker.module.scss";
import { FC, useEffect, useState } from "react";
import { setActiveTab, setEnvironmentVariant } from "../../redux/actions";
import {
  getActiveTab,
  getEnvironmentVariant,
} from "../../redux/selectors/selectors";
import { ArrowBack } from "../../assets/svg/ArrowBack";
import { categoryDataT } from "../../utils/categoriesData";
import { ImageItem } from "../../shared/ImageItem/ImageItem";
import { loadAsset } from "../../utils/functions/playcanvas/loadAsset";
import { ItemSettings } from "./components/ItemSettings/ItemSettings";
import { LogoWithText } from "../../assets/svg/LogoWithText";
type ImagePickerT = {
  data: categoryesListT;
};
export const ImagePicker: FC<ImagePickerT> = ({ ...props }) => {
  const { data } = props;
  const ParrentNameIfStaticEnv =
    data.data[0] &&
    data.data[0].parrentName &&
    staticEnvironments.includes(data.data[0].parrentName)
      ? data.data[0].parrentName
      : false;
  const [items, setItems] = useState<categoryDataT[]>(data.data);
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab());
  const activeEnvironmentVariant = useSelector(
    getEnvironmentVariant(ParrentNameIfStaticEnv)
  );
  function setActiveTabHandler(id: categoryId) {
    dispatch(setActiveTab({ activeTab: id }));
  }
  function back() {
    dispatch(setActiveTab({ activeTab: undefined }));
  }
  useEffect(() => {
    setItems(data.data);
  }, [data.data]);
  return (
    <div className={s.wrapper}>
      <div
        className={`${s.tab} ${data.data.length === 0 && s.disable}`}
        onClick={() => setActiveTabHandler(data.id)}
      >
        <div className={s.icon}>
          {data.icon && data.icon()}
          {data.img && <img src={data.img} alt="" />}
        </div>
        <div className={s.title}>{data.label}</div>
      </div>
      {activeTab === data.id && (
        <div className={s.content}>
          <div className={s.back} onClick={back}>
            <ArrowBack />
            <span>{data.label}</span>
            <div className={s.logo}>
              <LogoWithText />
            </div>
          </div>
          {data.type === "interior" && (
            <ItemSettings data={data} items={items} setItems={setItems} />
          )}
          <div className={s.items}>
            {items.map((item: categoryDataT, index: number) => {
              return (
                <ImageItem
                  item={item}
                  isActive={activeEnvironmentVariant === item.value}
                  onClickFunc={() => {
                    if (ParrentNameIfStaticEnv) {
                      dispatch(setEnvironmentVariant({envName: item.parrentName, envValue: item.value}))
                    } else {
                      loadAsset({
                        id: item.value,
                        assetId: item.glbBundle,
                        dispatch: dispatch,
                      });
                    }
                  }}
                  key={`${data.id}_${index}`}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
