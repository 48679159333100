import Notiflix from "notiflix";
// import "notyf/notyf.min.css";
type popUpT = {
  type: "warning" | "failure" | "success" | "info";
  message: string;
};

export const popUp = ({ ...props }: popUpT) => {
  const { type, message } = props;
  Notiflix.Notify[type](message, {
    timeout: 2000,
  });
};
