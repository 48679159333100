import { setSelectedModel } from "../../../redux/actions";
import store from "../../../redux/store";
import { getSelectionPos } from "./getSelectionPos";

export function checkModelSelected(dispatch: any, setCoords: any) {
  //@ts-ignore
  const containerRef = window.containerRef;
  setInterval(async () => {
    //@ts-ignore
    if (containerRef) {
      const selectedM = store.getState().ui.selectedModel;
      const canvasIframe = containerRef.current.contentWindow;
      const itemSelected =
        canvasIframe.itemSelected === undefined
          ? false
          : canvasIframe.itemSelected;

      if (selectedM !== itemSelected) {
        dispatch(setSelectedModel({ selectedModel: itemSelected }));
      }
      if (selectedM) {
        const selectionPos = await getSelectionPos();
        // console.log('coords: ', coords);
        if (selectionPos) {
          setCoords(selectionPos);
        }
      }
    }
  }, 20);
}
