import { FC, useEffect, useRef, useState } from "react";
import s from "./PlayCanvasIntegration.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setEnvironmentVariant,
  setSelectedModel,
} from "../../../../redux/actions";
import { getSelectedModel } from "../../../../redux/selectors/selectors";
import store from "../../../../redux/store";
import api from "../../../../processes/api";
import { loadConfiguration } from "../../../../utils/functions/playcanvas/loadConfiguration";
import { getConfigInfo } from "../../../../utils/functions/playcanvas/getConfigInfo";
export const PlayCanvasIntegration: FC = () => {
  const containerRef: any = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getSavedConfig() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const configParam: any = urlSearchParams.get("config");
      if (configParam) {
        const configJson = await api.getShortUrlApi.getShortUrl(configParam);
        try {
          // await getConfigInfo();
          const dataString = JSON.stringify(configJson);
          await loadConfiguration(dataString);
          configJson.environment.map((item: any) => {
            dispatch(
              setEnvironmentVariant({ envName: item.name, envValue: item.val })
            );
          });
        } catch {
          console.log("error");
          // getSavedConfig();
        }
      }
    }
    //@ts-ignore
    if (containerRef && !window.firstLoad) {
      //@ts-ignore
      window.containerRef = containerRef;
      //@ts-ignore
      window.firstLoad = true;
      containerRef.current.addEventListener("load", () => {
        setTimeout(() => {
          getSavedConfig();
        }, 1000);
      });
    }
  }, [containerRef]);

  return (
    <div className={s.wrapper}>
      <iframe
        title="scene"
        ref={containerRef}
        src="/playCanvasScene/index.html"
        id="demo"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
