import { FC, useEffect } from "react";
import s from "./InterfaceWidgets.module.scss";
import { Reset } from "../../assets/svg/Reset";
import { FullScreen } from "../../assets/svg/FullScreen";
import { RenderIcon } from "../../assets/svg/RenderIcon";
import { useDispatch, useSelector } from "react-redux";
import { changeShowModal } from "../../redux/actions";
import { resetScene } from "../../utils/functions/playcanvas/resetScene";
import { getServerStatus } from "../../redux/selectors/selectors";

export function exitHandler() {
  if (!document.fullscreenElement) {
    const closeButton = document.getElementById("closeFull");
    // closeButton!.style.display = "none";
    closeButton!.style.cssText = "display: none;";
  }
}

export const openFullScreen = () => {
  const player = document.getElementById("playerContainer");
  const closeButton = document.getElementById("closeFull");

  if (player && closeButton) {
    setTimeout(() => {
      // console.log('123123123123123123123123123');
      closeButton.style.cssText =
        "display: block !important; position: absolute; right: 50px; top: 50px; z-index: 999; cursor: pointer";
    }, 0);

    // player.requestFullscreen();
    var doc = window.document;
    var requestFullScreen =
      player.requestFullscreen ||
      //@ts-ignore
      player.mozRequestFullScreen ||
      //@ts-ignore
      player.webkitRequestFullScreen ||
      //@ts-ignore
      player.msRequestFullscreen;

    if (
      //@ts-ignore
      !doc.fullscreenElement &&
      //@ts-ignore
      !doc.mozFullScreenElement &&
      //@ts-ignore
      !doc.webkitFullscreenElement &&
      //@ts-ignore
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(player);
    }
  }
};

export const InterfaceWidgets: FC = () => {
  const dispatch = useDispatch();
  const serverStatus = useSelector(getServerStatus());
  useEffect(() => {
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);
  }, []);
  return (
    <div className={s.wrapper}>
      <div className={s.widgets}>
        <div className={s.fullWindow} onClick={() => openFullScreen()}>
          <FullScreen />
        </div>
        <div className={s.reset} onClick={resetScene}>
          <Reset />
          <span>Reset</span>
        </div>
      </div>
      <div
        className={s.render}
        onClick={() => {
          const dontShowNote = sessionStorage.getItem("dontShowNote");
          // if (serverStatus === "offline") {
            // dispatch(
            //   changeShowModal({
            //     nameModal: "serverOffline",
            //     stateModal: true,
            //   })
            // );
          // } else {
            if (dontShowNote === "true") {
              dispatch(
                changeShowModal({ nameModal: "renderLoader", stateModal: true })
              );
            } else {
              dispatch(
                changeShowModal({ nameModal: "pleaseNote", stateModal: true })
              );
            }
          // }
        }}
      >
        <RenderIcon />
        <span>Render image</span>
      </div>
    </div>
  );
};
