import { getCategories } from "./../../redux/selectors/selectors";
import axios, { AxiosError } from "axios";
import { ApiData } from "./renderAndLoadImage";
export type positionT = {
  x: number;
  y: number;
  z: number;
};
export type rotationT = {
  x: number;
  y: number;
  z: number;
};
export type camerasT = {
  id: number;
  name: string;
  position: positionT;
  rotation: rotationT;
  fov: number;
  rotationSteps: number;
  enabled: boolean;
};

export type variantsT = {
  id: number;
  name: string;
  image: string;
  enabled: boolean;
};
export type availableStagesT = {
  id: number;
  name: string;
  enabled: boolean;
  cameras: camerasT[];
};
export type availableOptionsT = {
  id: number;
  name: string;
  enabled: boolean;
  variants: variantsT[];
};
export type productsT = {
  categories: any;
  id: number;
  name: string;
  resource: string;
  enabled: boolean;
  projectId: number;
  availableStages: availableStagesT[];
  availableOptions: availableOptionsT[];
};
async function fetchEnvironmentData(
  adminURL: string,
  idProject: number,
  environmentOptions: any
) {
  // Создаем массив промисов для загрузки вариантов окружений
  const environmentPromises = environmentOptions.map(async (option: any) => {
    // Загружаем дополнительные данные для каждого варианта окружения
    const resData = await axios.get(
      `${adminURL}/environmentVariants?environmentOptionId=${option.id}&projectId=${idProject}&page=1&pageSize=500`
    );
    const environmentVariants: any[] = resData.data.rows.map((item: any) => {
      return {
        ...item,
        parrentId: item.id,
        parrentName: option.name,
        value: item.id,
        label: item.name,
        imgCover: item.image,
      };
    });

    // Создаем новый объект, объединяя начальные данные и полученные варианты
    return {
      ...option,
      rows: environmentVariants,
    };
  });

  // Дожидаемся выполнения всех промисов
  const environments = await Promise.all(environmentPromises);

  return environments;
}
export class productInfo extends ApiData {
  public async getEnvironment(body: { idProject: any }): Promise<any> {
    const { idProject } = body;
    try {
      const response = await axios.get(
        `${this.adminURL}/environmentOptions?projectId=${idProject}&page=1&pageSize=500`
      );
      const environmentOptions: any[] = response.data.rows;
      const environments = await fetchEnvironmentData(
        this.adminURL,
        idProject,
        environmentOptions
      );
      return environments;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        if (err.response) {
          return err.response.data;
        } else {
          return err.message;
        }
      }

      return "Error";
    }
  }
  public async getProduct(body: { idProject: any }): Promise<any> {
    const { idProject } = body;

    try {
      const response = await axios.get(
        `${this.adminURL}/products?projectId=${idProject}&page=1&pageSize=500`
      );

      const products: productsT[] = response.data;
      const filteredProducts: productsT[] = [];

      products.map((product: productsT) => {
        const filteredProduct: productsT = product;
        const availableStages: availableStagesT[] =
          product.availableStages.filter(
            (item: availableStagesT) => item.enabled
          );
        const availableOptions: availableOptionsT[] =
          product.availableOptions.filter(
            (item: availableOptionsT) => item.enabled
          );

        const stages: availableStagesT[] = [];
        availableStages.map((stage: availableStagesT) => {
          const enabledCameras: camerasT[] = stage.cameras.filter(
            (item: camerasT) => item.enabled
          );
          stages.push({ ...stage, cameras: enabledCameras });
        });

        const options: availableOptionsT[] = [];
        availableOptions.map((stage: availableOptionsT) => {
          const enabledVariants: variantsT[] = stage.variants.filter(
            (item: variantsT) => item.enabled
          );
          options.push({ ...stage, variants: enabledVariants });
        });

        filteredProduct.availableStages = stages;
        filteredProduct.availableOptions = options;
        if (filteredProduct.categories.length === 0) {
          filteredProduct.categories = [
            {
              name: "Other",
            },
          ];
        }
        filteredProducts.push(filteredProduct);
      });
      const res = filteredProducts.filter(
        (product: any) => product.enabled === true && product.glbBundle
      );

      return res;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        if (err.response) {
          return err.response.data;
        } else {
          return err.message;
        }
      }

      return "Error";
    }
  }

  public async getCategories(body: { idProject: any }): Promise<any> {
    const { idProject } = body;
    try {
      const response = await axios.get(
        `${this.adminURL}/categories?projectId=${idProject}&page=1&pageSize=500`
      );

      const categories: productsT[] = response.data.rows.map(
        (item: any) => item.name
      );
      return { keys: [...categories, "Other"], data: response.data.rows };
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        if (err.response) {
          return err.response.data;
        } else {
          return err.message;
        }
      }

      return "Error";
    }
  }
}
