import { setServerStatus } from "./../actions/index";
import { TYPE_REDUCER } from "../../utils/constants";
import { ModalStatusT, initialUiStateT } from "./types";

const initialState: initialUiStateT = {
  activeTab: undefined,
  selectedModel: false,
  selectedType: "none",
  wsConnected: false,
  serverStatus: "pending",
  renderHash: "",
  selectedVariants: {
    Background: {},
    Floor: {},
    Wall: {},
  },
  products: [],
  categories: [],
  environments: [],
  modalsList: {
    saveConfig: {
      isShow: false,
    },
    renderLoader: {
      isShow: false,
    },
    loader: {
      isShow: false,
    },
    renderPreview: {
      isShow: false,
    },
    pleaseNote: {
      isShow: false,
    },
    serverOffline: {
      isShow: false,
    },
  },
};

const reducer = (state = initialState, action: any): initialUiStateT => {
  switch (action.type) {
    case TYPE_REDUCER.SET_MODAL: {
      const { nameModal, stateModal, otherParams } = action.payload;
      const paramsModals: ModalStatusT = {
        isShow: stateModal,
        otherParams: otherParams,
      };

      return {
        ...state,
        modalsList: {
          ...state.modalsList,
          [nameModal]: paramsModals,
        },
      };
    }
    case TYPE_REDUCER.SET_PRODUCTS: {
      const { products } = action.payload;

      return {
        ...state,
        products,
      };
    }
    case TYPE_REDUCER.SET_ENVIRONMENTS: {
      const { environments } = action.payload;

      return {
        ...state,
        environments,
      };
    }

    case TYPE_REDUCER.SET_ENVIRONMENT_VARIANT: {
      const { envName, envValue } = action.payload;

      return {
        ...state,
        selectedVariants: {
          ...state.selectedVariants,
          [envName]: envValue,
        },
      };
    }
    case TYPE_REDUCER.SET_CATEGORIES: {
      const { categories } = action.payload;

      return {
        ...state,
        categories,
      };
    }
    case TYPE_REDUCER.SET_ACTIVE_TAB: {
      const { activeTab } = action.payload;
      return {
        ...state,
        activeTab,
      };
    }
    case TYPE_REDUCER.SET_WEBSOCKET_CONNECTED: {
      const { status } = action.payload;
      return {
        ...state,
        wsConnected: status,
      };
    }
    case TYPE_REDUCER.SET_RENDER_HASH: {
      const { hash } = action.payload;
      return {
        ...state,
        renderHash: hash,
      };
    }
    case TYPE_REDUCER.SET_SERVER_STATUS: {
      const { serverStatus } = action.payload;
      return {
        ...state,
        serverStatus,
      };
    }
    case TYPE_REDUCER.SET_SELECTED_MODEL: {
      const {
        selectedModel = state.selectedModel,
        selectedType = state.selectedType,
      } = action.payload;
      return {
        ...state,
        selectedModel,
        selectedType,
      };
    }

    default:
      return state;
  }
};

export default reducer;
