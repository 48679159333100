import { FC, useState } from "react";
import s from "./PlayerWidgets.module.scss";
import { CameraIcon } from "../../assets/svg/CameraIcon";
import { ArrowDropdown } from "../../assets/svg/ArrowDropdown";
import { Save } from "../../assets/svg/Save";
import { Checkbox } from "../../shared/Checkbox/Checkbox";
import { cameraList, cameraListT } from "../../utils/constants";
import { CameraDropdown } from "../../shared/CameraDropdown/CameraDropdown";
import { useDispatch } from "react-redux";
import { changeShowModal } from "../../redux/actions";
import { dimensionsToggle } from "../../utils/functions/playcanvas/dimensionsToggle";

export const PlayerWidgets: FC = () => {
  const [activeCamera, setActiveCamera] = useState<cameraListT>(cameraList[0]);
  const dispatch = useDispatch();
  function changeCamera(value: any) {
    console.log(1);
  }
  return (
    <div className={s.wrapper}>
      <div className={s.dimensions}>
        <Checkbox
          setIfActive={() => dimensionsToggle({show: true})}
          setIfDisable={() => dimensionsToggle({show: false})}
        />
        <span>Dimensions</span>
      </div>
      <CameraDropdown
        activeCamera={activeCamera}
        setActiveCamera={setActiveCamera}
      />
      <div
        className={s.save}
        onClick={() =>
          dispatch(
            changeShowModal({ nameModal: "saveConfig", stateModal: true })
          )
        }
      >
        <Save />
        <span>Save & Share</span>
      </div>
    </div>
  );
};
