import { FC } from "react";

export const FilterIcon: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M3.90039 3.90002C3.90039 3.76742 3.95307 3.64024 4.04684 3.54647C4.14061 3.4527 4.26778 3.40002 4.40039 3.40002H16.4004C16.533 3.40002 16.6602 3.4527 16.7539 3.54647C16.8477 3.64024 16.9004 3.76742 16.9004 3.90002V5.90002C16.9001 6.02315 16.8544 6.14184 16.7721 6.23336L12.4004 11.0917V15.9C12.4004 16.0049 12.3674 16.1071 12.306 16.1922C12.2447 16.2773 12.1582 16.341 12.0587 16.3742L9.05872 17.3742C8.98356 17.3993 8.90352 17.4062 8.82518 17.3942C8.74685 17.3823 8.67247 17.352 8.60817 17.3056C8.54388 17.2593 8.49151 17.1984 8.45538 17.1279C8.41925 17.0574 8.40041 16.9793 8.40039 16.9V11.0917L4.02789 6.23336C3.94583 6.14171 3.90044 6.02304 3.90039 5.90002V3.90002ZM4.90039 4.40002V5.70836L9.27206 10.5667C9.35442 10.6582 9.40011 10.7769 9.40039 10.9V16.2059L11.4004 15.54V10.9C11.4004 10.777 11.4458 10.6583 11.5279 10.5667L15.9004 5.70836V4.40002H4.90039Z"
          fill="#7D7D8A"
        />
      </g>
    </svg>
  );
};
